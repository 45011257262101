import dayjs from 'dayjs';
import { onValue, ref } from 'firebase/database';
import React, { PropsWithChildren, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ADVERTISEMENT } from '../../../../api/config/routes';
import { baseHttp } from '../../../../api/http/base';
import { IAdvertisementModel } from '../../../../models/data/advertisement.model';
import { RootState } from '../../../../store';
import { IPage } from '../models/page.model';
import classes from './PageContent.module.scss';

const PageContent = (props: PropsWithChildren<IPage>) => {
  const [aktivniOglasi, setAktivniOglasi] = useState(0);

  useEffect(() => {
    const dbRef = baseHttp.getDatabase();
    const advertisements = ref(dbRef, ADVERTISEMENT);
    onValue(advertisements, snapshot => {
      const objectData = snapshot.val();
      let listData: IAdvertisementModel[] = [];

      if (objectData) {
        listData = Object.keys(objectData)
          .reduce((acc, curr) => {
            acc.push(objectData[curr]);
            return acc;
          }, [] as IAdvertisementModel[])
          .filter(advert => {
            // proverka dali rok_na_traenje e pogolemo od momentalniot datum
            const now = new Date(dayjs().format('YYYY-MM-DD')).getTime();

            const rok_na_traenje = new Date(dayjs(advert.rok_na_traenje).format('YYYY-MM-DD')).getTime();

            return now <= rok_na_traenje;
          });
      }

      setAktivniOglasi(state => listData.length);
    });
  }, []);

  const username = useSelector((state: RootState) => {
    return `${state.auth.user.user?.first_name} ${state.auth.user.user?.last_name}`;
  });
  const userValue = useSelector((state: RootState) => {
    return state.auth.user.user;
  });
  let classList: any[] = [];

  if (!props.fullscreen && !props.hideSecondaryNav && !props.borderlessFullscreen) {
    classList = ['container page-content table-responsive'];
  } else {
    classList = ['page-content table-responsive'];
  }

  if (props.hideSecondaryNav) {
    classList.push(classes.no_secondary_nav);
  }

  if (props.borderless) {
    classList.push(classes.Borderless);
  }

  if (props.white) {
    classList.push(classes.White);
  }

  if (props.borderlessFullscreen) {
    classList.push(classes.BorderlessFullscreen);
  }

  if (props.borderlessWithMarginTop) {
    classList.push(classes.BorderlessWithMarginTop);
  }

  const translations = useSelector((state: RootState) => {
    return state.translations.translations[state.translations.appLanguage]['page'];
  });

  return (
    <React.Fragment>
      <div className={classList.join(' ')}>
        {!props.fullscreen && props.pageTitle && (
          <div className={classes.PageTitle}>
            <h5>{props.pageTitle}</h5>
          </div>
        )}
        {!props.fullscreen && !props.hideSecondaryNav && !props.pageTitle && (
          <div className={classes.PageTitle} style={{ height: props.table ? '3vh' : 'auto' }}>
            <div className={classes.LoggedUserContainer}>
              <span className={classes.LoggedLabel}>{translations['Најавени како']} </span>
              <span className={classes.LoggedUser}>
                {username} #{userValue?.id}
              </span>
            </div>
            {/* <div>
                            {translations["Активни огласи"]}:{" "}
                            <span className={classes.ActiveAdvertisement}>
                                {aktivniOglasi}
                            </span>
                        </div> */}
          </div>
        )}
        <div
          className={`${classes.PageContent} ${props.fullscreen ? classes.FullScreen : null}`}
          style={{ height: props.table ? '73vh' : 'auto' }}
        >
          {props.children}
        </div>
      </div>
    </React.Fragment>
  );
};

export default PageContent;
