import React, { useMemo } from 'react';
import { Modal } from 'react-bootstrap';
import classes from '../ConfirmModalCompany/ConfirmModalCompany.module.scss';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../store';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Input } from '../../../../../components/Tools/useFormComponents/exports';
import { TextArea } from '../../../../../components/Tools/useFormComponents/TextArea/TextArea';
import Documents from './Documents';
import { UseMutationResult } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { EditDataType, useEditOffer } from '../../../../../queries/offer';
import GlobalLoader from '../../../../../components/UI/GlobalLoader/GlobalLoader';

export type Offer = {
  amount: { id: number; offer_id: number; price: number; currency: string; status: string; updated_at: string };
  catalog_number: string;
  comment: string;
  created_at: string;
  delivery_due_days: number;
  documents: { created_at: string; id: number; name: string; offer_id: number; path: string; updated_at: string }[];
  id: number;
  product: { id: number; title: string };
  quantity: number;
  updated_at: string;
};

type Props = {
  openEditModal: {
    show: boolean;
    data: Offer;
  };
  handleEditModalClose: () => void;
};

const EditOfferModal = ({ openEditModal, handleEditModalClose }: Props) => {
  const t = useSelector((state: RootState) => {
    return state.translations.translations[state.translations.appLanguage];
  });

  const editOffer = useEditOffer();

  const offerValidationSchema = useMemo(
    () =>
      Yup.object({
        id: Yup.number(),
        catalog_number: Yup.string().required(t.profile.input_required),
        quantity: Yup.number().typeError(t.create_ad.must_be_number).required(t.profile.input_required),
        price: Yup.number()
          .typeError(t.create_ad.must_be_number)
          .min(1, t.create_ad.input_must_be_one)
          .required(t.profile.input_required),
        comment: Yup.string().required(t.profile.input_required),
        delivery_due_days: Yup.number().typeError(t.create_ad.must_be_number).required(t.profile.input_required),
        documents: Yup.array()
          .of(
            Yup.object().shape({
              name: Yup.string(),
              file: Yup.string(),
            })
          )
          .required(t.profile.input_required),
      }),
    [t]
  );

  const {
    formState: { errors, isValid, isSubmitting, touchedFields },
    control,
    setValue,
    register,
    handleSubmit,
    getValues,
    getFieldState,
  } = useForm<Yup.InferType<typeof offerValidationSchema>>({
    resolver: yupResolver(offerValidationSchema),
    defaultValues: {
      id: openEditModal.data.id,
      catalog_number: openEditModal.data.catalog_number,
      quantity: openEditModal.data.quantity,
      price: openEditModal.data?.amount?.price,
      comment: openEditModal.data.comment,
      delivery_due_days: openEditModal.data.delivery_due_days,
      documents: openEditModal.data.documents,
    },
  });

  return (
    <Modal show={openEditModal.show} className={classes.modal_wrapper} centered size="lg" onHide={handleEditModalClose}>
      <Modal.Header className={classes.custom_header} closeButton>
        <Modal.Title>{t.product.offer_details}</Modal.Title>
      </Modal.Header>
      {editOffer.isLoading ? (
        <GlobalLoader />
      ) : (
        <form
          noValidate
          onSubmit={handleSubmit(values => {
            editOffer.mutate({ data: values });
            handleEditModalClose();
          })}
        >
          <Modal.Body className={classes.custom_body}>
            <div className={classes.space}>
              <Input
                label={t.offers.catalog_number}
                {...register(`catalog_number`)}
                error={getFieldState(`catalog_number`).error?.message}
              />
              <div className={classes.split_form}>
                <div className={classes.split_form_inner}>
                  <Input
                    label={t.offers.due_date_offered}
                    {...register(`delivery_due_days`)}
                    error={getFieldState(`delivery_due_days`).error?.message}
                  />
                </div>
                <div className={classes.split_form_inner}>
                  <Input
                    label={t.offers.offered_quantity}
                    {...register(`quantity`)}
                    error={getFieldState(`quantity`).error?.message}
                  />
                </div>
                <div className={`${classes.price_wrapper} ${classes.split_form_inner}`}>
                  <Input
                    label={t.product.price}
                    {...register(`price`)}
                    error={getFieldState(`price`).error?.message}
                    customInputWidth="85%"
                  />
                  <div className={classes.currency}>({t.currencies.MKD})</div>
                </div>
              </div>
            </div>
            <Documents control={control} />

            <div className={classes.comment_wrapper}>
              <TextArea
                label={t.product.comment}
                {...register(`comment`)}
                error={getFieldState(`comment`).error?.message}
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className={classes.actions}>
              <div className={classes.buttons_wrapper}>
                <button className={classes.refuse_btn} onClick={() => handleEditModalClose()}>
                  <span className={classes.icon}></span>
                  {/* {t.current_advertisements.no} */}
                </button>
              </div>

              <div className={classes.buttons_wrapper}>
                <button type="submit" className={classes.accept_btn}>
                  <span className={classes.icon}></span>
                  {/* {t.current_advertisements.yes} */}
                </button>
              </div>
            </div>
          </Modal.Footer>
        </form>
      )}
    </Modal>
  );
};

export default EditOfferModal;
