import { useSelector } from 'react-redux';
import clsx from 'clsx';

import classes from './StatusDisplay.module.scss';
import { RootState } from '../store';
import { ProductOfferStatus } from '../queries/offer';

interface Props {
  status: ProductOfferStatus;
}

export function ProductOfferStatusDisplay({ status }: Props) {
  const t = useSelector((state: RootState) => {
    return state.translations.translations[state.translations.appLanguage]['advertisements'];
  });

  return (
    <div
      className={clsx(classes.container, {
        [classes.pending]: status === ProductOfferStatus.Pending,
        [classes.accepted]: status === ProductOfferStatus.Actualized,
      })}
    >
      <span />
      {t[status]}
    </div>
  );
}
