import { useSelector } from 'react-redux';
import clsx from 'clsx';

import classes from './StatusDisplay.module.scss';
import { RootState } from '../store';
import { OfferStatus } from '../types/offer';
import { CSSProperties } from 'react';

interface Props {
  status: OfferStatus;
  statusNames?: { accepted?: string; declined?: string; in_progress?: string };
  style?: CSSProperties;
}

export function OfferStatusDisplay({ status, statusNames, style }: Props) {
  const t = useSelector((state: RootState) => {
    return state.translations.translations[state.translations.appLanguage]['offers'];
  });

  return (
    <div
      className={clsx(classes.container, {
        [classes.pending]: status === OfferStatus.Pending,
        [classes.accepted]: status === OfferStatus.Accepted,
        [classes.rejected]: status === OfferStatus.Rejected,
      })}
      style={style}
    >
      <span></span>
      {status === OfferStatus.Accepted
        ? statusNames?.accepted ?? t.accepted
        : status === OfferStatus.Rejected
        ? statusNames?.declined ?? t.declined
        : statusNames?.in_progress ?? t.in_progress}
    </div>
  );
}
