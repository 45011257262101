import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { client } from '../services/http/instance';
import { showNotification } from '../notification_functions/notifications_functions';
import { useSelector } from 'react-redux';
import { RootState } from '../store';

export interface Industry {
  id: number;
  name: string;
  created_at: string;
  updated_at: string;
  deleted_at: string | null;
}

export function useIndustries(query?: {
  sortOder?: string;
  sortBy?: string;
  search?: string;
  status?: string;
  dateFrom?: string;
  dateTo?: string;
  adId?: number;
  view?: 'ads' | 'offers';
}) {
  return useQuery({
    queryKey: ['industries', query],
    queryFn: () => client.get<Industry[]>('/industries', { params: query }).then(({ data }) => data),
    keepPreviousData: true,
    staleTime: typeof query?.adId !== 'undefined' || typeof query?.view !== 'undefined' ? 1000 * 60 : undefined,
  });
}

export function useUpdateIndustry(id: number) {
  const queryClient = useQueryClient();
  const t = useSelector((state: RootState) => state.translations.translations[state.translations.appLanguage]);

  return useMutation({
    mutationFn: (name: string) => client.put(`/industries/${id}`, { name }),
    onError: () => {
      showNotification(t.global.error_message, 'danger');
    },
    onSuccess: (_, name) => {
      showNotification(t.industry.industry_updated, 'success');

      queryClient.setQueriesData<Industry[]>(['industries'], old =>
        old?.map(industry => (industry.id === id ? { ...industry, name } : industry))
      );
    },
  });
}

export function useDeleteIndustry(id: number) {
  const queryClient = useQueryClient();
  const t = useSelector((state: RootState) => state.translations.translations[state.translations.appLanguage]);

  return useMutation({
    mutationFn: () => client.delete(`/industries/${id}`),
    onError: () => {
      showNotification(t.global.error_message, 'danger');
    },
    onSuccess: () => {
      showNotification(t.industry.industry_deleted, 'success');

      queryClient.setQueriesData<Industry[]>(['industries', { status: 'active' }], old =>
        old?.filter(industry => industry.id !== id)
      );
    },
  });
}

export function useRestoreIndustry(id: number) {
  const queryClient = useQueryClient();
  const t = useSelector((state: RootState) => state.translations.translations[state.translations.appLanguage]);

  return useMutation({
    mutationFn: () => client.post(`/industries/${id}/restore`),
    onError: () => {
      showNotification(t.global.error_message, 'danger');
    },
    onSuccess: () => {
      showNotification(t.industry.industry_restored, 'success');

      queryClient.setQueriesData<Industry[]>(['industries', { status: 'inactive' }], old =>
        old?.filter(industry => industry.id !== id)
      );
    },
  });
}

export function useCreateIndustry() {
  const queryClient = useQueryClient();
  const t = useSelector((state: RootState) => state.translations.translations[state.translations.appLanguage]);

  return useMutation({
    mutationFn: (name: string) => client.post<Industry>(`/industries`, { name }).then(({ data }) => data),
    onError: () => {
      showNotification(t.global.error_message, 'danger');
    },
    onSuccess: () => {
      showNotification(t.industry.industry_created, 'success');

      queryClient.invalidateQueries<Industry[]>(['industries', { status: 'active' }], { exact: false });
    },
  });
}
