import React, { forwardRef, InputHTMLAttributes, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import * as FileSaver from 'file-saver';
import AdvertisementForm from './AdvertisementForm/AdvertisementForm';
import Switch from '../../../../../components/UI/Switch/Switch';
import RadioButton from '../../../../../components/UI/RadioButton/RadioButton';
import Datepicker from '../../../../../components/UI/Datepicker/Datepicker';
import { RootState } from '../../../../../store';
import { httpPost } from '../../../../../services/http';
import GlobalLoader from '../../../../../components/UI/GlobalLoader/GlobalLoader';
import { showNotification } from '../../../../../notification_functions/notifications_functions';
import * as Yup from 'yup';
import BasePhoneInput, { Props as BasePhoneInputProps, isValidPhoneNumber } from 'react-phone-number-input/input';
import ClockIcon from '../../../../../assets/icons/Clock-icon.svg';
import classes from './BaseInfo.module.scss';
import { Controller, set, useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Form from 'react-bootstrap/Form';
import { download } from '../../../../../utils/download';
import dayjs from 'dayjs';
import axios from 'axios';
import { client } from '../../../../../services/http/instance';
import ConfirmModal from './Modals/ConfirmRemoveModal/ConfirmModal';
import { useIndustries } from '../../../../../queries/industry';
import { TimePicker } from '../../../../../components/TimePicker';
import Checkbox from '../../../../../components/UI/Checkbox/Checkbox';
import { useAuth } from '../../../../../contexts/AuthContext';

interface BaseInputProps {
  label: string;
  error?: string;
}

interface InputProps extends InputHTMLAttributes<HTMLInputElement>, BaseInputProps {}

const Input = forwardRef<HTMLInputElement, InputProps>(({ label, required, error, ...props }, ref) => {
  return (
    <div className={[classes.input_wrapper, error && classes.has_error].filter(Boolean).join(' ')}>
      <label>
        {label}
        {required && <span>*</span>}
      </label>
      <input ref={ref} {...props} />
      <div className={classes.error}>{error}</div>
    </div>
  );
});

interface PhoneInputProps extends BasePhoneInputProps<InputHTMLAttributes<HTMLInputElement>>, BaseInputProps {}

const PhoneInput = forwardRef<HTMLInputElement, PhoneInputProps>(({ error, required, label, ...props }, ref) => {
  return (
    <div className={[classes.input_wrapper, error && classes.has_error].filter(Boolean).join(' ')}>
      <label>
        {label}
        {required && <span>*</span>}
      </label>
      <BasePhoneInput {...props} ref={ref} />
      <div className={classes.error}>{error}</div>
    </div>
  );
});

const BaseInfo = ({ advertisement, id }: any) => {
  const { REACT_APP_MAX_LEGAL_DOCUMENTS, REACT_APP_MAX_ADDITIONAL_DOCUMENTS, REACT_APP_FILES_TYPE }: any = process.env;

  const { user } = useAuth();

  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);

  const translations = useSelector((state: RootState) => {
    return state.translations.translations[state.translations.appLanguage]['create_ad'];
  });

  const t = useSelector((state: RootState) => {
    return state.translations.translations[state.translations.appLanguage]['global'];
  });

  const t_product = useSelector((state: RootState) => {
    return state.translations.translations[state.translations.appLanguage]['product'];
  });

  const { data: categories = [] } = useIndustries({ adId: id });

  function formatTime(seconds) {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secs = Math.floor(seconds % 60);
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${secs
      .toString()
      .padStart(2, '0')}`;
  }

  const onDocumentChange = (e, name) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.readAsDataURL(file);

    reader.onload = e => {
      if (e.target) {
        let filename = file.name;
        let dataFile = e.target.result;

        if (typeof dataFile !== 'string') return;

        if (name === 'pravniDokumenti') {
          setValue(
            name,
            [
              // ...getValues(name),
              {
                file_name: filename,
                file_url: dataFile,
                existing: false,
              },
            ],
            { shouldValidate: true }
          );
        } else {
          setValue(
            name,
            [
              ...getValues(name),
              {
                file_name: filename,
                file_url: dataFile,
                existing: false,
              },
            ],
            { shouldValidate: true }
          );
        }
      }
    };
    e.target.value = '';
  };

  const onDocumentRemove = (index, name) => {
    if (getValues(name)[index]) {
      setValue(
        name,
        getValues(name).filter((value, id) => id !== index),
        { shouldValidate: true }
      );
    }
  };

  const downloadFile = (file, isFromServer) => {
    if (isFromServer) {
      download(`/ads/${id}/files/${file.id}`);
    } else {
      FileSaver.saveAs(file.file_url, file.file_name);
    }
  };

  const confirmAdDelete = async () => {
    setShowConfirmModal(false);
    setIsLoading(true);
    try {
      const response = await httpPost<any, any>('/tender/delete?XDEBUG_SESSION_START=phpstorm', {
        ad_id: advertisement.id,
      });
      if (response.message === 'Success') {
        navigate('/my-advertisements');
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };

  const validationSchema = useMemo(
    () =>
      Yup.object({
        contactPerson: Yup.object({
          name: Yup.string().required(translations.input_required),
          phone: Yup.string()
            .required(translations.input_required)
            .test('phone', translations.invalid_phone_number, value => isValidPhoneNumber(value)),
          email: Yup.string().required(translations.input_required).email(translations.invalid_email),
        }),
        anonymous: Yup.bool(),
        adsFormValues: Yup.array().of(
          Yup.object().shape({
            quantity: Yup.number()
              .typeError(translations.must_be_number)
              .min(1, translations.at_least_one_product)
              .required(translations.input_required),
            description: Yup.string().required(translations.input_required),
            title: Yup.string().required(translations.input_required),
            category: Yup.string(),
            category_id: Yup.string(),
            // id: Yup.number().nullable(),
            images: Yup.array()
              .min(1, translations.image_fields_min)
              .max(10, translations.image_fields_max)
              .of(
                Yup.object().shape({
                  existing: Yup.boolean(),
                  file_url: Yup.string().required(translations.input_required),
                  file_type: Yup.number(),
                })
              ),
            additional_documents: Yup.array()
              .max(10, translations.document_fields_max)
              .of(
                Yup.object().shape({
                  existing: Yup.boolean(),
                  file_url: Yup.string().required(translations.input_required),
                  file_name: Yup.string().required(translations.input_required),
                })
              ),
          })
        ),
        pravniDokumenti: Yup.array()
          .required(translations.input_required)
          .min(1, translations.document_fields_min)
          .max(10, translations.document_fields_max)
          .of(
            Yup.object().shape({
              existing: Yup.boolean(),
              file_url: Yup.string().required(translations.input_required),
              file_name: Yup.string().required(translations.input_required),
            })
          ),
        title: Yup.string().required(translations.input_required),
        industryId: Yup.string().required(translations.input_required),
        // datum_isporaka: Yup.string().required(translations.input_required),
        aukcija: Yup.boolean(),
        datum_oddrzuvanje_aukcija: Yup.string()
          .nullable()
          .when('aukcija', {
            is: val => val === true,
            then: val => val.required(translations.input_required),
          }),
        od_vreme_oddrzuvanje_aukcija: Yup.string().when('aukcija', {
          is: val => val === true,
          then: val => val.required(translations.input_required),
        }),
        do_vreme_oddrzuvanje_aukcija: Yup.string()
          .when('aukcija', {
            is: val => val === true,
            then: val => val.required(translations.input_required),
          })
          .test('dates', translations.invalid_auction_end_time, function (value) {
            if (
              !this.parent.aukcija ||
              !this.parent.datum_oddrzuvanje_aukcija ||
              !this.parent.od_vreme_oddrzuvanje_aukcija
            ) {
              return true;
            }

            return (
              new Date(`${this.parent.datum_oddrzuvanje_aukcija} ${value}`) >
              new Date(`${this.parent.datum_oddrzuvanje_aukcija} ${this.parent.od_vreme_oddrzuvanje_aukcija}`)
            );
          }),
        datum_za_dostavuvanje: Yup.string()
          .required(translations.input_required)
          .test('dates', translations.invalid_offer_due_date, function (value) {
            if (
              !this.parent.aukcija ||
              !this.parent.datum_oddrzuvanje_aukcija ||
              !this.parent.od_vreme_oddrzuvanje_aukcija
            ) {
              return true;
            }

            return (
              new Date(`${this.parent.datum_oddrzuvanje_aukcija} ${this.parent.od_vreme_oddrzuvanje_aukcija}`) >=
              new Date(`${value} 00:00`)
            );
          }),
        datum_za_prasanja: Yup.string().required(translations.input_required),
        vreme_za_prasanja: Yup.string().required(translations.input_required),
        // datum_za_odgovori: Yup.string().required(translations.input_required),
        vreme_za_odgovori: Yup.string().required(translations.input_required),
        dostavuvanje_ponudi_vreme: Yup.string()
          .required(translations.input_required)
          .test('dates', translations.invalid_offer_due_time, function (value) {
            if (
              !this.parent.aukcija ||
              !this.parent.datum_oddrzuvanje_aukcija ||
              !this.parent.od_vreme_oddrzuvanje_aukcija ||
              !this.parent.datum_za_dostavuvanje
            ) {
              return true;
            }

            // if the error is the date, don't show the time error
            if (
              new Date(`${this.parent.datum_za_dostavuvanje} 00:00`) >
              new Date(`${this.parent.datum_oddrzuvanje_aukcija} ${this.parent.od_vreme_oddrzuvanje_aukcija}`)
            ) {
              return true;
            }

            return (
              new Date(`${this.parent.datum_oddrzuvanje_aukcija} ${this.parent.od_vreme_oddrzuvanje_aukcija}`) >=
              new Date(`${this.parent.datum_za_dostavuvanje} ${value}`)
            );
          }),
        kriterium: Yup.number().required(translations.input_required),
        // anonimno: Yup.boolean()
        id: Yup.number(),
        delivery_due_days: Yup.number()
          .typeError(translations.must_be_number)
          .min(1, translations.input_must_be_one)
          .required(translations.input_required),
      }),
    [t, translations]
  );

  const {
    formState: { errors },
    control,
    setValue,
    register,
    handleSubmit,
    getValues,
  } = useForm<Yup.InferType<typeof validationSchema>>({
    resolver: yupResolver(validationSchema),
    defaultValues: advertisement
      ? {
          contactPerson: {
            name: `${user?.first_name} ${user?.last_name}`,
            phone: advertisement?.contact_phone,
            email: user?.email,
          },
          anonymous: advertisement.anonymous ?? false,
          title: advertisement.title,
          adsFormValues: advertisement.products,
          pravniDokumenti: advertisement.pravni_dokumenti,
          industryId: advertisement.industry.id,
          // datum_isporaka: advertisement.datum_isporaka,
          delivery_due_days: advertisement.delivery_due_days,
          kriterium: advertisement.kriterium,
          aukcija: advertisement.aukcija,
          datum_oddrzuvanje_aukcija: advertisement.auction_start_date
            ? dayjs(advertisement.auction_start_date).format('YYYY-MM-DD')
            : undefined,
          od_vreme_oddrzuvanje_aukcija: advertisement.auction_start_date
            ? dayjs(advertisement.auction_start_date).format('HH:mm:ss')
            : '00:00:00',
          do_vreme_oddrzuvanje_aukcija: advertisement.auction_end_date
            ? dayjs(advertisement.auction_end_date).format('HH:mm:ss')
            : '00:00:00',

          datum_za_dostavuvanje: advertisement.offer_due_date
            ? dayjs(advertisement.offer_due_date).format('YYYY-MM-DD')
            : undefined,

          dostavuvanje_ponudi_vreme: advertisement.offer_due_date
            ? dayjs(advertisement.offer_due_date).format('HH:mm:ss')
            : '00:00:00',

          datum_za_prasanja: advertisement.datum_za_prasanja,
          // datum_za_odgovori: advertisement.datum_za_odgovori,

          vreme_za_prasanja: advertisement.vreme_za_prasanja ?? '00:00:00',
          vreme_za_odgovori: advertisement.vreme_za_odgovori ?? '00:00:00',
          // anonimno: false,
        }
      : {
          anonymous: false,
          contactPerson: {
            name: `${user?.first_name} ${user?.last_name}`,
            phone: user?.company.phone_number ?? '',
            email: user?.email,
          },
          adsFormValues: [
            {
              quantity: 1,
              description: '',
              images: [],
              title: '',
              additional_documents: [],
            },
          ],
          pravniDokumenti: [],
          title: '',
          industryId: '',
          aukcija: false,
          delivery_due_days: 1,

          vreme_za_prasanja: '08:00:00',
          vreme_za_odgovori: '08:00:00',
          // anonimno: false,
          dostavuvanje_ponudi_vreme: '08:00:00',
          od_vreme_oddrzuvanje_aukcija: '08:00:00',
          do_vreme_oddrzuvanje_aukcija: '08:00:00',
        },
  });

  const { fields, append, remove, update } = useFieldArray({
    control,
    name: 'adsFormValues',
    keyName: '_id',
    rules: {
      minLength: 1,
      required: true,
    },
  });

  return (
    <form
      noValidate
      onSubmit={handleSubmit(async values => {
        const {
          datum_oddrzuvanje_aukcija,
          od_vreme_oddrzuvanje_aukcija,
          do_vreme_oddrzuvanje_aukcija,
          datum_za_dostavuvanje,
          dostavuvanje_ponudi_vreme,
          aukcija,
        } = values;

        setIsLoading(true);

        const auction_start_date =
          datum_oddrzuvanje_aukcija && od_vreme_oddrzuvanje_aukcija
            ? new Date(`${datum_oddrzuvanje_aukcija} ${od_vreme_oddrzuvanje_aukcija}`).toISOString()
            : undefined;

        const auction_end_date =
          datum_oddrzuvanje_aukcija && do_vreme_oddrzuvanje_aukcija
            ? new Date(`${datum_oddrzuvanje_aukcija} ${do_vreme_oddrzuvanje_aukcija}`).toISOString()
            : undefined;

        const offer_due_date = new Date(`${datum_za_dostavuvanje} ${dostavuvanje_ponudi_vreme}`).toISOString();

        const isUpdating = !!advertisement;

        try {
          if (isUpdating) {
            await client.post('/tender/update', {
              ...values,
              ad_id: id,
              auction_start_date,
              auction_end_date,
              offer_due_date,
            });

            showNotification(translations.ad_updated, 'success');
          } else {
            await httpPost<any, any>('/tender/create', {
              ...values,
              auction_start_date,
              auction_end_date,
              offer_due_date,
            });

            showNotification(translations.ad_created, 'success');
          }

          navigate('/my-advertisements');
        } catch (error: any) {
          setIsLoading(false);

          if (error.error_code === 'product_limit_exceeded') {
            showNotification(translations.auction_product_limit, 'danger');
          } else if (isUpdating && axios.isAxiosError(error) && error.response?.status === 409) {
            showNotification(translations.forbidden_ad_type_change, 'danger');
          } else if (axios.isAxiosError(error) && error.response?.status === 403) {
            showNotification(translations.forbidden_update, 'danger');
          } else {
            showNotification(t.error_message, 'danger');
          }
        }
      })}
    >
      <div className={classes.contact_person_wrapper}>
        <div className={classes.label_title}>{translations.contact_person}</div>
        <div className={classes.contact_person_form}>
          <div className={classes.col_one}>
            <div className={`${classes.input_wrapper} ${!!errors?.contactPerson?.name ? classes.has_error : ''}`}>
              <Input
                required
                label={translations.name_surname}
                type="text"
                {...register('contactPerson.name')}
                error={errors?.contactPerson?.name?.message}
                disabled
              />
            </div>
            <div className={`${classes.input_wrapper} ${!!errors?.contactPerson?.phone ? classes.has_error : ''}`}>
              <Controller
                control={control}
                name="contactPerson.phone"
                render={({ field, fieldState }) => (
                  <PhoneInput
                    required
                    ref={field.ref}
                    value={field.value}
                    onChange={value => setValue('contactPerson.phone', value ?? '', { shouldValidate: true })}
                    label={translations.phone_number}
                    error={fieldState.error?.message}
                  />
                )}
              />
            </div>
          </div>
          <div className={classes.col_two}>
            <div className={`${classes.input_wrapper} ${!!errors?.contactPerson?.email ? classes.has_error : ''}`}>
              <Input
                required
                label={translations.email}
                type="text"
                {...register('contactPerson.email')}
                error={errors?.contactPerson?.email?.message}
                disabled
              />
            </div>
          </div>
        </div>
      </div>

      <div className={classes.contact_person_wrapper}>
        <label className={classes.label_title}>{t_product.ad_title}</label>
        <div className={classes.contact_person_form}>
          <div className={`${classes.input_wrapper} ${errors?.title?.message ? classes.has_error : ''}`}>
            <Input
              required
              label={translations.ad_title}
              type="text"
              {...register('title')}
              error={errors?.title?.message}
            />
          </div>
          <div className={`${classes.input_wrapper} ${errors?.industryId ? classes.has_error : ''}`}>
            <label>{translations.category_title}</label>

            <Controller
              name={`industryId`}
              control={control}
              render={({ field }) => (
                <Form.Select
                  name={`industryId`}
                  value={field.value}
                  style={{
                    padding: '8px 2.25rem 8px .75rem',
                  }}
                  onChange={e => {
                    setValue('industryId', e.target.value, { shouldValidate: true });
                  }}
                >
                  {categories?.length > 0 ? (
                    <>
                      <option value="">{translations.choose_category}</option>
                      {categories.map((value, i) => {
                        return (
                          <option value={value.id} key={i}>
                            {value.name}
                          </option>
                        );
                      })}
                    </>
                  ) : (
                    <option>{translations.no_categories}</option>
                  )}
                </Form.Select>
              )}
            />

            <div className={classes.error}> {errors?.industryId?.message}</div>
          </div>
        </div>
      </div>

      <div className={classes.administrative_info_wrapper}>
        <div className={classes.label_title}>{translations.administrative_info_title}</div>
        <div className={classes.administrative_info}>
          <div className={classes.row_two}>
            <div className={classes.inputs_wrapper}>
              <label>{translations.submission_last_date}</label>
              <div className={classes.inputs_wrapper}>
                <div className={classes.inputs_container}>
                  <div className={classes.input_wrapper}>
                    <Controller
                      control={control}
                      name="datum_za_dostavuvanje"
                      render={({ field, fieldState }) => (
                        <>
                          <Datepicker
                            field={{ value: field.value ? new Date(field.value) : null, name: field.name }}
                            onInputChange={date => {
                              setValue('datum_za_dostavuvanje', date, { shouldValidate: true });
                            }}
                            ref={field.ref}
                            preventPreviousDates={true}
                            customClasses={classes.data_picker_wrapper}
                            required={true}
                            hasError={!!errors?.datum_za_dostavuvanje}
                            placeholder=""
                          />
                          {errors.datum_za_dostavuvanje && (
                            <div className={classes.error}>{errors.datum_za_dostavuvanje.message}</div>
                          )}
                        </>
                      )}
                    />
                  </div>
                  <div className={classes.input_wrapper}>
                    <Controller
                      control={control}
                      name="dostavuvanje_ponudi_vreme"
                      render={({ field, fieldState }) => (
                        <>
                          <TimePicker
                            step={30}
                            start="00:00"
                            ref={field.ref}
                            id="dostavuvanje_ponudi_vreme"
                            end="23:30"
                            value={field.value}
                            onChange={time => {
                              let formattedTime = formatTime(time);
                              setValue('dostavuvanje_ponudi_vreme', formattedTime, { shouldValidate: true });
                            }}
                            format={24}
                          />
                          <img src={ClockIcon} alt="Clock" />
                          {errors?.dostavuvanje_ponudi_vreme && (
                            <div className={classes.error}>{errors?.dostavuvanje_ponudi_vreme?.message}</div>
                          )}
                        </>
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className={classes.inputs_wrapper}>
              <label>{translations.deadline_for_questions}</label>

              <div className={classes.inputs_container}>
                <div className={`${classes.input_wrapper} ${!!errors?.datum_za_prasanja ? classes.has_error : ''}`}>
                  <Controller
                    control={control}
                    name="datum_za_prasanja"
                    render={({ field, fieldState }) => (
                      <>
                        <Datepicker
                          field={{ value: field.value ? new Date(field.value) : null, name: field.name }}
                          onInputChange={date => {
                            setValue('datum_za_prasanja', date, { shouldValidate: true });
                          }}
                          preventPreviousDates={true}
                          ref={field.ref}
                          customClasses={classes.data_picker_wrapper}
                          required={true}
                          hasError={!!errors?.datum_za_prasanja}
                          placeholder=""
                        />
                        {errors.datum_za_prasanja && (
                          <div className={classes.error}>{errors.datum_za_prasanja.message}</div>
                        )}
                      </>
                    )}
                  />
                </div>
                <div className={`${classes.input_wrapper} ${errors?.vreme_za_prasanja ? classes.has_error : ''}`}>
                  <Controller
                    control={control}
                    name="vreme_za_prasanja"
                    render={({ field, fieldState }) => (
                      <>
                        <TimePicker
                          step={30}
                          ref={field.ref}
                          id="vreme_za_prasanja"
                          start="00:00"
                          end="23:30"
                          value={field.value}
                          onChange={time => {
                            let formattedTime = formatTime(time);
                            setValue('vreme_za_prasanja', formattedTime, { shouldValidate: true });
                          }}
                          format={24}
                        />
                        <img src={ClockIcon} alt="Clock" />

                        {errors?.vreme_za_prasanja && (
                          <div className={classes.error}>{errors?.vreme_za_prasanja?.message}</div>
                        )}
                      </>
                    )}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className={classes.row_two}>
            {/* <div className={classes.inputs_wrapper}>
              <label>{translations.deadline_for_answers}</label>
              <div className={classes.inputs_container}>
                <div className={classes.input_wrapper}>
                  <Controller
                    control={control}
                    name="datum_za_odgovori"
                    render={({ field, fieldState }) => (
                      <>
                        <Datepicker
                          field={{ value: field.value ? new Date(field.value) : null, name: field.name }}
                          onInputChange={date => {
                            setValue('datum_za_odgovori', date, { shouldValidate: true });
                          }}
                          preventPreviousDates={true}
                          customClasses={classes.data_picker_wrapper}
                          required={true}
                          hasError={!!errors?.datum_za_odgovori}
                          placeholder=""
                        />
                        {errors.datum_za_odgovori && (
                          <div className={classes.error}>{errors.datum_za_odgovori.message}</div>
                        )}
                      </>
                    )}
                  />
                </div>
                <div className={classes.input_wrapper}>
                  <Controller
                    control={control}
                    name="vreme_za_odgovori"
                    render={({ field, fieldState }) => (
                      <>
                        <TimePicker
                          step={30}
                          start="00:00"
                          end="23:30"
                          value={field.value}
                          onChange={time => {
                            let formattedTime = formatTime(time);
                            setValue('vreme_za_odgovori', formattedTime, { shouldValidate: true });
                          }}
                          format={24}
                        />
                        <img src={ClockIcon} alt="Clock" />

                        {errors?.vreme_za_odgovori && (
                          <div className={classes.error}>{errors?.vreme_za_odgovori?.message}</div>
                        )}
                      </>
                    )}
                  />
                  <img src={ClockIcon} alt="Clock" />
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>

      <div className={classes.auction_wrapper}>
        <div className={classes.label_title}>{translations.auction_title}</div>
        <div className={classes.inputs_wrapper}>
          <div className={classes.switch_wrapper}>
            <label>{translations.auction_title}</label>
            <div className={classes.switch}>
              <Controller
                control={control}
                name="aukcija"
                render={({ field }) => (
                  <>
                    <label>{t.no}</label>
                    <Switch
                      label=""
                      name="aukcija"
                      checked={field.value}
                      onChange={value => setValue('aukcija', value, { shouldValidate: true })}
                      customClasses={classes.custom_switch}
                    />
                    <label className={classes.active}>{t.yes}</label>
                  </>
                )}
              />
            </div>
          </div>
          <div className={classes.auction_date}>
            <div className={`${classes.date_wrapper} ${!!errors?.datum_oddrzuvanje_aukcija ? classes.has_error : ''}`}>
              <Controller
                control={control}
                name="datum_oddrzuvanje_aukcija"
                render={({ field, fieldState }) => (
                  <>
                    <label>{translations.auction_date}</label>

                    <Datepicker
                      field={{ value: field.value ? new Date(field.value) : null, name: field.name }}
                      onInputChange={date => {
                        setValue('datum_oddrzuvanje_aukcija', date, { shouldValidate: true });
                      }}
                      ref={field.ref}
                      preventPreviousDates={true}
                      customClasses={classes.data_picker_wrapper}
                      required={true}
                      hasError={!!errors?.datum_oddrzuvanje_aukcija}
                      containerClass={!getValues('aukcija') ? classes.auctionDateDisabled : ''}
                      disabled={!getValues('aukcija')}
                      placeholder=""
                    />
                    {errors.datum_oddrzuvanje_aukcija && (
                      <div className={classes.error}>{errors.datum_oddrzuvanje_aukcija.message}</div>
                    )}
                  </>
                )}
              />
            </div>
            <div
              className={`${classes.time_from_wrapper} ${
                !!errors?.od_vreme_oddrzuvanje_aukcija ? classes.has_error : ''
              }`}
            >
              <div className={classes.input_wrapper}>
                <label>{t.from}:</label>

                <Controller
                  control={control}
                  name="od_vreme_oddrzuvanje_aukcija"
                  render={({ field, fieldState }) => (
                    <>
                      <TimePicker
                        step={30}
                        ref={field.ref}
                        id="od_vreme_oddrzuvanje_aukcija"
                        start="00:00"
                        end="23:30"
                        value={field.value}
                        onChange={time => {
                          let formattedTime = formatTime(time);
                          setValue('od_vreme_oddrzuvanje_aukcija', formattedTime, { shouldValidate: true });
                        }}
                        format={24}
                        disabled={!getValues('aukcija')}
                      />
                      <img src={ClockIcon} style={{ top: '39px' }} alt="Clock" />

                      {errors.od_vreme_oddrzuvanje_aukcija && (
                        <div className={classes.error}>{errors.od_vreme_oddrzuvanje_aukcija.message}</div>
                      )}
                    </>
                  )}
                />
              </div>
            </div>
            <div
              className={`${classes.time_to_wrapper} ${
                !!errors?.do_vreme_oddrzuvanje_aukcija ? classes.has_error : ''
              }`}
            >
              <label>{t.to}:</label>
              <div className={classes.input_wrapper}>
                <Controller
                  control={control}
                  name="do_vreme_oddrzuvanje_aukcija"
                  render={({ field }) => (
                    <>
                      <TimePicker
                        step={30}
                        start="00:00"
                        end="23:30"
                        ref={field.ref}
                        id="do_vreme_oddrzuvanje_aukcija"
                        value={field.value}
                        onChange={time => {
                          let formattedTime = formatTime(time);
                          setValue('do_vreme_oddrzuvanje_aukcija', formattedTime, { shouldValidate: true });
                        }}
                        format={24}
                        disabled={!getValues('aukcija')}
                      />
                      <img src={ClockIcon} alt="Clock" />

                      {errors?.do_vreme_oddrzuvanje_aukcija && (
                        <div className={classes.error}>{errors?.do_vreme_oddrzuvanje_aukcija?.message}</div>
                      )}
                    </>
                  )}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={classes.ad_time_and_criteria_wrapper}>
        <div className={classes.label_title}>{translations.date_and_criteria_title}</div>
        <div className={classes.form_wrapper}>
          {/* <div className={classes.input_wrapper}>
            <Controller
              control={control}
              name="datum_isporaka"
              render={({ field, fieldState }) => (
                <div>
                  <label>{translations.delivery_date_title}</label>
                  <Datepicker
                    field={{ value: field.value ? new Date(field.value) : null, name: field.name }}
                    onInputChange={date => {
                      setValue('datum_isporaka', date, { shouldValidate: true });
                    }}
                    preventPreviousDates={true}
                    customClasses={classes.data_picker_wrapper}
                    required={true}
                    containerStyle={{
                      maxWidth: '360px',
                    }}
                  />
                  {errors.datum_isporaka && <div className={classes.error}>{errors.datum_isporaka.message}</div>}
                </div>
              )}
            />
          </div> */}

          <Input
            required
            label={translations.delivery_date_title}
            type="number"
            {...register('delivery_due_days')}
            error={errors?.delivery_due_days?.message}
          />
          <div className={classes.input_wrapper}>
            <label>{translations.criteria_title}</label>

            <div className={classes.radio_buttons_wrapper}>
              <Controller
                control={control}
                name="kriterium"
                render={({ field, fieldState }) => (
                  <React.Fragment>
                    <RadioButton
                      label={translations.no_criteria}
                      name="kriterium"
                      value="0"
                      checked={field.value == 0}
                      customClasses={classes.criteria_wrapper}
                      onChange={() => setValue('kriterium', 0, { shouldValidate: true })}
                    />
                    <RadioButton
                      label={translations.lowest_price}
                      name="kriterium"
                      value="1"
                      checked={field.value == 1}
                      customClasses={classes.criteria_wrapper}
                      onChange={() => setValue('kriterium', 1, { shouldValidate: true })}
                    />
                    <RadioButton
                      label={translations.quality}
                      name="kriterium"
                      value="2"
                      checked={field.value == 2}
                      customClasses={classes.criteria_wrapper}
                      onChange={() => setValue('kriterium', 2, { shouldValidate: true })}
                    />
                  </React.Fragment>
                )}
              />
            </div>
            <div>{errors.kriterium && <div className={classes.error}>{errors.kriterium.message}</div>}</div>
          </div>
        </div>
      </div>

      <div className={classes.technical_info_wrapper}>
        <div className={classes.label_title}>{translations.technical_info_title}</div>
        <div className={classes.technical_info}>
          <label>{translations.registered_activity}</label>
          <div className={classes.uploaded_docs}>
            {getValues('pravniDokumenti').map((value, i) => {
              return (
                <div className={classes.uploaded_doc} key={i}>
                  <div className={classes.document_icon}></div>
                  <div
                    className={classes.file_name}
                    onClick={() => downloadFile(value, !value.hasOwnProperty('existing'))}
                  >
                    {value.file_name}
                  </div>
                  {/* <div
                    className={classes.close_icon}
                    onClick={() => onDocumentRemove(i, 'pravniDokumenti')}
                  ></div> */}
                </div>
              );
            })}
          </div>
          <div className={`${classes.upload_doc} ${errors?.pravniDokumenti?.message ? classes.has_error : ''}`}>
            <>
              <div
                className={`${classes.attach_wrapper} ${classes.basic_button}`}
                onClick={() => {
                  document.getElementById('add_document')?.click();
                }}
              >
                <div className={classes.icon}></div>
                <div className={classes.text}>{translations.add_new_docs}</div>
              </div>
            </>

            <Controller
              control={control}
              name="pravniDokumenti"
              render={({ field, fieldState }) => (
                <>
                  <input
                    type="file"
                    accept={'.pdf,image/png,image/jpeg'}
                    style={{ display: 'none' }}
                    onChange={e => onDocumentChange(e, field.name)}
                    name="add_document"
                    id="add_document"
                  />
                  {errors?.pravniDokumenti && <div className={classes.error}>{errors?.pravniDokumenti?.message}</div>}
                </>
              )}
            />
          </div>
        </div>
      </div>

      <div className={classes.ads_wrapper}>
        <div className={classes.label_title}>{translations.product}</div>
        <div id="ads_form_wrapper">
          {fields?.map((value, index) => (
            <AdvertisementForm
              key={index}
              formIndex={index}
              values={value}
              register={register}
              forms={getValues('adsFormValues') && getValues('adsFormValues')?.length}
              errors={errors}
              update={update}
              setValue={setValue}
              remove={remove}
              categories={categories}
              control={control}
              append={append}
              advertisement={!!advertisement}
              getValues={getValues}
              translations={translations}
              downloadFile={downloadFile}
              onDocumentRemove={onDocumentRemove}
            />
          ))}
        </div>
      </div>

      <div className={classes.horizontal_line}></div>

      <div
        className={classes.buttons_wrapper}
        style={advertisement ? { justifyContent: 'space-between' } : { justifyContent: 'flex-end' }}
      >
        <div className={classes.left_buttons}>
          {advertisement && (
            <button
              type="button"
              className={`${classes.delete_btn} ${classes.base_btn}`}
              onClick={() => setShowConfirmModal(true)}
            >
              {translations.delete_ad}
            </button>
          )}
          <Controller
            control={control}
            name="anonymous"
            render={({ field }) => (
              <Checkbox
                label={translations.post_anonymously}
                id="anonymous"
                large
                onValueChangeBoolean={field.onChange}
                value={field.value}
                checked={field.value}
              />
            )}
          />
        </div>
        <div className={classes.divider}>
          <button type="button" className={`${classes.cancel_btn} ${classes.base_btn}`} onClick={() => navigate(-1)}>
            {t.cancel}
          </button>
          <button type="submit" className={`${classes.save_btn} ${classes.basic_button}`}>
            <div className={classes.text}>{t.save}</div>
          </button>
        </div>
      </div>

      <ConfirmModal
        show={showConfirmModal}
        toggleModal={() => setShowConfirmModal(false)}
        confirm={confirmAdDelete}
        title={translations.delete_ad}
        confirmation={translations.remove_confirm_text}
      />
      <GlobalLoader show={isLoading} />
    </form>
  );
};

export default BaseInfo;
