import React, { useMemo, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { RootState } from '../../../../store';
import { RoutesEnum } from '../../../../enums/routes';

import MessageIcon from '../../../../assets/icons/Message.svg';
import NoImageIcon from '../../../../assets/icons/No-image-icon.svg';
import Info from '../../../../assets/icons/Info.svg';

import classes from './ConfirmModalCompany/ConfirmModalCompany.module.scss';
import {
  AdditionalOffersQuery,
  OfferProductStatus,
  ProductOffer,
  useCompanyOffers,
  useUpdateStatus,
} from '../../../../queries/offer';
import { Ad } from '../../../../queries/ad';
import { getLogoUrl } from '../../../../utils/company';
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  PaginationTotalStandalone,
  SizePerPageDropdownStandalone,
} from 'react-bootstrap-table2-paginator';
import BootstrapTable from 'react-bootstrap-table-next';
import { OfferStatusDisplay } from '../../../../components/OfferStatusDisplay';
import EditOfferModal, { Offer } from './EditOfferModal/EditOfferModal';
import DeleteOfferModal from './EditOfferModal/DeleteOfferModal';
import ViewOfferModal from './ViewOfferModal/ViewOfferModal';

interface Props {
  show: boolean;
  confirmBtnText?: string | null;
  data: ProductOffer;
  ad: Ad;
  onHandleClose: () => void;
  own?: boolean;
  product: any;
}

export function OfferCompanyModal({ show, data, ad, product, own, onHandleClose }: Props) {
  const [query, setQuery] = useState<AdditionalOffersQuery>({
    pagination: { page: 1, size: 10 },
  });
  const [openEditModal, setOpenEditModal] = useState<{ show: boolean; data: Offer }>({
    show: false,
    data: {},
  } as { show: boolean; data: Offer });
  const [openDeleteModal, setOpenDeleteModal] = useState({ show: false, id: null });
  const [openViewModal, setOpenViewModal] = useState<{ show: boolean; data: Offer }>({
    show: false,
    data: {},
  } as { show: boolean; data: Offer });
  const update = useUpdateStatus();
  const navigate = useNavigate();
  const offers = useCompanyOffers(data.company.id.toString(), product.id, query);

  const t = useSelector((state: RootState) => {
    return state.translations.translations[state.translations.appLanguage];
  });

  const user = useSelector((state: RootState) => state.auth.user.user);

  const t_global = t.global;

  const handleDeleteModalClose = () => {
    setOpenDeleteModal(prev => ({ ...prev, show: false }));
  };

  const handleOfferEditClose = () => {
    setOpenEditModal(prev => ({ ...prev, show: false }));
  };

  const priceFormatter = (price: number) => {
    return Intl.NumberFormat().format(price);
  };

  const columns = useMemo(
    () => [
      {
        dataField: 'product.title',
        text: t.product.product,
        formatter: (cell, row) => {
          return (
            <div className={classes.productName} onClick={() => setOpenViewModal(prev => ({ show: true, data: row }))}>
              <img src={Info} />
              <span>{cell}</span>
            </div>
          );
        },
      },
      {
        dataField: 'catalog_number',
        text: t.offers.catalog_number,
        sort: true,
      },
      {
        dataField: 'quantity',
        text: t.offers.offered_quantity,
        sort: true,
      },
      {
        dataField: 'amount.price',
        text: t.product.price,
        formatter: (cell, row) => {
          return priceFormatter(Number(cell));
        },
        sort: true,
      },
      {
        dataField: 'full_price',
        text: t.offers.total_price,
        formatter: (cell, row) => {
          return priceFormatter(Number(row.amount.price) * Number(row.quantity));
        },
        // sort: true
      },
      {
        dataField: 'delivery_due_days',
        text: t.offers.due_date_offered,
        sort: true,
      },
      {
        dataField: 'amount.status',
        text: t.offers.status,
        formatter: (cell, row) => <OfferStatusDisplay status={cell} statusNames={{ in_progress: t.offers.offered }} />,
        sort: true,
      },
      {
        dataField: 'actions',
        text: '',
        // hidden: ad && !dayjs().isAfter(dayjs(ad?.auction_end_date)),
        formatter: (cell, row) => {
          if (row.amount.status === OfferProductStatus.PENDING) {
            if (!own) {
              return (
                <div className={classes.actions}>
                  <div className={classes.buttons_wrapper}>
                    <button
                      className={classes.refuse_btn}
                      onClick={() => update.mutate({ status: OfferProductStatus.DECLINED, id: row.id })}
                    >
                      <span className={classes.icon}></span>
                      {/* {t.my_adds_offers.reject_offer} */}
                    </button>
                    <button
                      className={classes.accept_btn}
                      onClick={() => update.mutate({ status: OfferProductStatus.ACCEPTED, id: row.id })}
                    >
                      <span className={classes.icon}></span>
                      {/* {confirmBtnText || t.my_adds_offers.accept_offer} */}
                    </button>
                  </div>
                </div>
              );
            } else {
              return (
                <div className={classes.actions}>
                  <div className={classes.buttons_wrapper}>
                    <button className={classes.edit_btn} onClick={() => setOpenEditModal({ show: true, data: row })}>
                      <span className={classes.icon}></span>
                      {/* {t.my_adds_offers.edit_offer} */}
                    </button>
                    <button
                      className={classes.delete_btn}
                      onClick={() => setOpenDeleteModal({ show: true, id: row.id })}
                    >
                      <span className={classes.icon}></span>
                      {/* {confirmBtnText || t.my_adds_offers.delete_offer} */}
                    </button>
                  </div>
                </div>
              );
            }
          }
        },
      },
    ],
    [t, own, update.mutate]
  );

  function caret(order, column) {
    if (!order) {
      return (
        <span style={{ display: 'inline-block', position: 'relative', top: '-5px' }}>
          <span className={classes.sort_wrapper}>
            <span className={`${classes.caret} ${classes.caret_up}`}></span>
            <span className={classes.caret}></span>
          </span>
        </span>
      );
    } else if (order === 'asc') {
      return (
        <span style={{ display: 'inline-block', position: 'relative', top: '-2px' }}>
          <span className={classes.sort_wrapper}>
            <span className={`${classes.caret} ${classes.caret_up}`}></span>
          </span>
        </span>
      );
    } else if (order === 'desc') {
      return (
        <span style={{ display: 'inline-block', position: 'relative', top: '-2px' }}>
          <span className={classes.sort_wrapper}>
            <span className={classes.caret}></span>
          </span>
        </span>
      );
    } else {
      return null;
    }
  }

  const total = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      {`${t_global['table_total_text']} ${from} ${t_global['table_total_text_1']} ${to} ${t_global['table_total_text_2']} ${size} ${t_global['table_total_text_3']}`}
    </span>
  );

  const paginationOptions = {
    custom: true,
    totalSize: offers.data?.total ?? 0,
    page: query.pagination.page,
    sizePerPage: query.pagination.size,
    paginationSize: 4,
    pageStartIndex: 1,
    alwaysShowAllBtns: true,
    withFirstAndLast: false,
    hideSizePerPage: false,
    hidePageListOnlyOnePage: false,
    firstPageText: '<<',
    prePageText: '<',
    nextPageText: '>',
    lastPageText: '>>',
    nextPageTitle: 'First page',
    prePageTitle: 'Pre page',
    firstPageTitle: 'Next page',
    lastPageTitle: 'Last page',
    showTotal: true,
    paginationTotalRenderer: total,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: '10',
        value: 10,
      },
      {
        text: '50',
        value: 50,
      },
      {
        text: '100',
        value: 100,
      },
    ],
  };

  const onTableChange = (_type, { page, sizePerPage, sortField, sortOrder }) => {
    setQuery(prev => ({ ...prev, pagination: { page, size: sizePerPage }, sort: { sortBy: sortField, sortOrder } }));
  };

  const handleOfferViewClose = () => {
    setOpenViewModal(prev => ({ ...prev, show: false }));
  };

  const company = own ? ad.company : data.company;
  const isAnonymous = ad.anonymous && own;

  return (
    <React.Fragment>
      <Modal
        show={show}
        className={classes.modal_wrapper}
        centered
        size="xl"
        onHide={onHandleClose}
        style={{ zIndex: 1050 }}
      >
        <Modal.Header className={classes.custom_header} closeButton>
          <Modal.Title>{t.my_adds_offers.company}</Modal.Title>
        </Modal.Header>
        <Modal.Body className={classes.custom_body}>
          <div className={classes.company_info_wrapper}>
            <div className={classes.image_wrapper}>
              <div className={classes.image_container}>
                <img
                  src={getLogoUrl(company)}
                  alt="company logo"
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null;
                    currentTarget.src = NoImageIcon;
                  }}
                />
              </div>
              <div className={classes.company_name}>{isAnonymous ? t_global.anonymous : company.naziv}</div>
            </div>
            {!isAnonymous && (
              <>
                <div className={classes.company_info}>
                  <div className={classes.company_name}>{company.naziv}</div>
                  <div className={classes.website}>{company.web_location}</div>
                  {/* <div className={classes.email}>{ad.company.email}</div> */}
                  <div className={classes.address}>{company.address}</div>
                  <div className={classes.phone}>{company.phone_number}</div>
                </div>
                <div className={classes.message_icon}>
                  <div
                    className={classes.image_wrapper}
                    // TODO: fix types for /ads/:id and /products/:id/offers
                    onClick={() =>
                      navigate(
                        `/messages/${(company as any).user.id}-${user.id}?receiverId=${(company as any).user.id}`
                      )
                    }
                  >
                    <img src={MessageIcon} alt="message icon" />
                  </div>
                </div>
              </>
            )}
          </div>
          <div className={classes.contact_wrapper}>
            <div>
              <div className={classes.title}>{t.my_adds_offers.contact_person}</div>
              <div className={classes.contact_info_wrapper}>
                <div className={classes.name}>{ad.contact_name}</div>
                {/* <div className={classes.email}>{ad.contact_email}</div> */}
              </div>
            </div>
            <div>
              <div className={classes.title}>{t.my_adds_offers.asked_quantity}</div>
              <div className={classes.contact_info_wrapper}>
                <div className={classes.name}>{product.quantity}</div>
              </div>
            </div>
            <div>
              <div className={classes.title}>{t.my_adds_offers.asked_delivery_days}</div>
              <div className={classes.contact_info_wrapper}>
                <div className={classes.name}>{ad.delivery_due_days}</div>
              </div>
            </div>
            <div className={classes.phone}>{ad.contact_phone}</div>
          </div>
          <div className={classes.table_wrapper}>
            <div className={classes.table_container}>
              <PaginationProvider pagination={paginationFactory(paginationOptions)}>
                {({ paginationProps, paginationTableProps }) => (
                  <div>
                    <BootstrapTable
                      keyField="id"
                      remote={{ pagination: true }}
                      onTableChange={onTableChange}
                      data={offers.data?.data ?? []}
                      columns={columns}
                      classes={classes.offers_table}
                      noDataIndication={offers.isLoading ? t_global.loading : t_global.no_data}
                      sort={{ sortCaret: caret }}
                      {...paginationTableProps}
                    />
                    <div className={classes.pagination_wrapper}>
                      <PaginationTotalStandalone {...paginationProps} />
                      <div>
                        <PaginationListStandalone {...paginationProps} />
                        <SizePerPageDropdownStandalone {...paginationProps} />
                      </div>
                    </div>
                  </div>
                )}
              </PaginationProvider>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {openDeleteModal.show && (
        <DeleteOfferModal handleDeleteModalClose={handleDeleteModalClose} openDeleteModal={openDeleteModal} />
      )}
      {openEditModal.show && (
        <EditOfferModal openEditModal={openEditModal} handleEditModalClose={handleOfferEditClose} />
      )}
      {openViewModal.show && (
        <ViewOfferModal openViewModal={openViewModal} handleOfferViewClose={handleOfferViewClose} ad={ad} />
      )}
    </React.Fragment>
  );
}
