import React from 'react';
import { useDeleteOffer } from '../../../../../queries/offer';
import { Modal } from 'react-bootstrap';
import classes from '../ConfirmModalCompany/ConfirmModalCompany.module.scss';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../store';

type Props = {
  handleDeleteModalClose: () => void;
  openDeleteModal: {
    show: boolean;
    id: null;
  };
};

const DeleteOfferModal = ({ handleDeleteModalClose, openDeleteModal }: Props) => {
  const t = useSelector((state: RootState) => {
    return state.translations.translations[state.translations.appLanguage];
  });

  const deleteOffer = useDeleteOffer();

  const handleOfferDelete = id => {
    deleteOffer?.mutate({ id });
    handleDeleteModalClose();
  };
  return (
    <Modal
      show={openDeleteModal.show}
      className={classes.modal_wrapper}
      centered
      //@ts-ignore
      size="md"
      onHide={handleDeleteModalClose}
    >
      <Modal.Header className={classes.custom_header} closeButton>
        <Modal.Title
          style={{ fontSize: '19px' }}
        >{`${t.my_adds_offers.delete_confirmation} #${openDeleteModal.id}?`}</Modal.Title>
      </Modal.Header>
      <Modal.Body className={classes.custom_body}>
        <div className={classes.actions}>
          <div className={classes.buttons_wrapper}>
            <button className={classes.refuse_btn} onClick={() => handleDeleteModalClose()}>
              {/* <span className={classes.icon}></span> */}
              {t.current_advertisements.no}
            </button>
          </div>

          <div className={classes.buttons_wrapper}>
            <button className={classes.accept_btn} onClick={() => handleOfferDelete(openDeleteModal.id)}>
              {/* <span className={classes.icon}></span> */}
              {t.current_advertisements.yes}
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default DeleteOfferModal;
