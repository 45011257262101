import React, { useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { ReactNotifications } from 'react-notifications-component';

import { RootState } from './store';
import { RoutesEnum } from './enums/routes';
import { initFirebase } from './api/config/firebase';
import GlobalLoader from './components/UI/GlobalLoader/GlobalLoader';

import Login from './pages/Auth/Login/Login';
import Register from './pages/Auth/Register/Register';
import Advertisements from './pages/Advertisements/Advertisements';
import Chat from './pages/Chat/Chat';
import NewAd from './pages/NewAd/NewAd';
import CurrentAdvertisements from './pages/CurrentAdvertisements/ListAdvertisements/CurrentAdvertisements';
import TermsAndServices from './pages/TermsAndServices/TermsAndServices';
import ProfilePage from './pages/Profile/ProfilePage';
import AdvertisementPreview from './pages/AdvertisementPreview/AdvertisementPreview';
import Auction from './pages/Auction/Auctions/Auctions';
import NotificationsPage from './pages/NotificationsPage/NotificationsPage';
import ResetPassword from './pages/ResetPassword/ResetPassword';
import CompanyInfo from './pages/CompanyInfo/CompanyInfo';
import AuctionBidding from './pages/AuctionBidding/AuctionBidding';
import AdvertisementEdit from './pages/AdvertisementEdit/AdvertisementEdit';

// My Offers
import MyOffers from './pages/MyOffers/OffersList/Offers';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  BarElement,
  Filler,
} from 'chart.js';

import translationsMk from './assets/i18n/mk.json';
import translationsEn from './assets/i18n/en.json';
import translationsAl from './assets/i18n/al.json';
import { translationsActions } from './store/translations/translations';

import 'react-notifications-component/dist/theme.css';
import { OffersPreviewNew } from './pages/OffersPreview/OfferPreviewNew';
import { Role } from './store/auth/auth.model';
import { Industries } from './pages/Industries/Industries';
import { useAuth } from './contexts/AuthContext';

const APP_ENV = process.env.REACT_APP_ENV;
const APP_VERSION = process.env.REACT_APP_VERSION;

initFirebase();

function App() {
  const dispatch = useDispatch();
  const isLogged = useSelector((state: RootState) => state.auth.isLogged);
  const token = useSelector((state: RootState) => state.auth.token);
  const auth = useAuth();

  const isLoading = useSelector((state: RootState) => state.http.isLoading);

  useEffect(() => {
    ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, BarElement, Title, Tooltip, Legend, Filler);
  });

  useEffect(() => {
    const translations = {
      mk: translationsMk,
      en: translationsEn,
      al: translationsAl,
    };

    dispatch(translationsActions.loadTranslations(translations));
  }, [dispatch]);

  // this fixes (somehow) an error -> auth fail on page reload
  const firebaseAuth = getAuth();
  onAuthStateChanged(firebaseAuth, user => {
    if (user) {
      // User is signed in, see docs for a list of available properties
      // https://firebase.google.com/docs/reference/js/firebase.User
      // const uid = user.uid;
      // ...
    } else {
      // User is signed out
      // ...
    }
  });

  const isAdmin = auth.hasRole(Role.SystemAdmin);

  if (isLoading || auth.isLoading) {
    return <GlobalLoader />;
  }

  return (
    <>
      <ReactNotifications />
      <Routes>
        {!auth.isAuthenticated ? (
          <>
            <Route path="/" element={<Navigate to={RoutesEnum.Login} />} />
            <Route path={RoutesEnum.Login} element={<Login />} />
            <Route path="/reset-password/:token" element={<Login />} />
            <Route path={RoutesEnum.Register} element={<Register />} />
            <Route path={RoutesEnum.ResetPassword} element={<ResetPassword />} />
            <Route path="*" element={<Navigate to={RoutesEnum.Login} />} />
          </>
        ) : (
          <>
            <Route path={RoutesEnum.Messages} element={<Chat />} />
            <Route path={RoutesEnum.MessageView} element={<Chat />} />
            <Route path={RoutesEnum.Advertisements} element={<Advertisements />} />
            <Route path={RoutesEnum.AdvertisementPreview} element={<AdvertisementEdit />} />
            <Route path={RoutesEnum.OffersPreview} element={<OffersPreviewNew />} />
            <Route path={RoutesEnum.CurrentAdvertisements} element={<CurrentAdvertisements />} />
            <Route path={RoutesEnum.CurrentAdvertisementPreview} element={<AdvertisementPreview />} />
            <Route path={RoutesEnum.TermsOfService} element={<TermsAndServices />} />
            <Route path={RoutesEnum.Auction} element={<Auction />} />
            <Route path={RoutesEnum.AuctionPreview} element={<AuctionBidding />} />
            <Route path={RoutesEnum.Notifications} element={<NotificationsPage />} />
            <Route path={RoutesEnum.Profile} element={<ProfilePage />} />
            <Route path={RoutesEnum.New} element={<NewAd />} />
            <Route path={RoutesEnum.CompanyInfo} element={<CompanyInfo />} />
            <Route path={RoutesEnum.AuctionBidding} element={<AuctionBidding />} />
            <Route path={RoutesEnum.MyOffers} element={<MyOffers />} />
            <Route path={RoutesEnum.MyOfferPreview} element={<OffersPreviewNew own />} />
            <Route path={RoutesEnum.MyOffersAuction} element={<AuctionBidding />} />
            {isAdmin && (
              <>
                <Route path={RoutesEnum.Industries} element={<Industries />} />
              </>
            )}
            <Route path="*" element={<Navigate to={isAdmin ? RoutesEnum.Industries : RoutesEnum.Advertisements} />} />
          </>
        )}
      </Routes>
      {APP_ENV === 'stage' && (
        <div style={{ position: 'fixed', left: '1rem', bottom: '0.5rem', color: '#000', fontSize: 14, zIndex: 999 }}>
          v{APP_VERSION}
        </div>
      )}
    </>
  );
}

export default App;
