import { useEffect, useMemo, useState } from 'react';
import classes from './MenuChart.module.scss';

import { Line } from 'react-chartjs-2';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import { useStats } from '../../../../queries/company';

const plugins = [
  {
    id: 'custom_canvas_background_color',
    beforeDraw: chart => {
      const { ctx } = chart;
      ctx.save();
      ctx.globalCompositeOperation = 'destination-over';
      ctx.fillStyle = '#0044bb';
      ctx.fillRect(0, 0, chart.width, chart.height);
      ctx.restore();
    },
  },
];

const options = {
  backgroundColor: 'red',
  lineTension: 0.5,
  responsive: true,
  maintainAspectRatio: false,
  elements: {
    point: {
      radius: 0,
    },
  },
  scales: {
    x: {
      display: false,
      ticks: {
        display: false,
      },
      grid: {
        display: false,
      },
    },
    y: {
      display: false,
      ticks: {
        display: false,
      },
      grid: {
        display: false,
      },
    },
  },
  plugins: {
    title: {
      display: false,
    },
    legend: {
      display: false,
    },
  },
};

const MenuChart = () => {
  const { data } = useStats();
  const [isMounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  const chart = useMemo(() => {
    if (!data || !isMounted) {
      return null;
    }

    const canvas: HTMLCanvasElement = document.getElementById('dummy') as HTMLCanvasElement;
    const ctx = canvas.getContext('2d');

    const gradient = ctx!.createLinearGradient(0, 0, 0, 510);
    gradient.addColorStop(0, 'rgba(255, 255, 255, 1)');
    gradient.addColorStop(0.5, 'rgba(100, 100, 0,0)');

    const labels = data.daily.map(x => x.date);
    const values = data.daily.map(x => x.total);

    return (
      <Line
        data={{
          labels,
          datasets: [
            {
              label: 'New companies in the last 7 days',
              data: values,
              fill: true,
              borderColor: 'white',
              backgroundColor: gradient,
            },
          ],
        }}
        plugins={plugins}
        options={options}
      />
    );
  }, [data, isMounted]);

  const translations = useSelector((state: RootState) => {
    return state.translations.translations[state.translations.appLanguage]['sidemenu'];
  });

  return (
    <>
      <canvas id="dummy" className={classes.Dummy}></canvas>
      <div className={classes.MainContainer}>
        <div className={classes.ChartSummary}>
          <div className={classes.Info}>
            <div className={classes.Label}>{translations['Вкупно компании']}</div>
            <div className={classes.Value}>{data?.total}</div>
          </div>
          <div className={classes.InfoDimmed}>
            <div className={classes.Label}>
              +{data?.new} {translations['нови компании']}
            </div>
          </div>
        </div>
        <div className={classes.Container}>{chart}</div>
      </div>
    </>
  );
};

export default MenuChart;
