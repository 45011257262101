import DumpsterFireTimePicker from 'react-bootstrap-time-picker';
import { forwardRef } from 'react';

export const TimePicker = forwardRef<HTMLLabelElement, any>(({ id, ...rest }, ref) => {
  return (
    <>
      {ref && <label htmlFor={id} ref={ref} style={{ display: 'none' }} />}
      <DumpsterFireTimePicker id={id} {...rest} />
    </>
  );
});
