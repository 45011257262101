import classes from '../ConfirmModalCompany//ConfirmModalCompany.module.scss';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../store';
import { toBase64 } from '../../../../../utils/toBase64';
import { showNotification } from '../../../../../notification_functions/notifications_functions';
import { Control, useFieldArray } from 'react-hook-form';
import FileSaver from 'file-saver';
import PlusIcon from '../../../../../assets/icons/Plus.svg';
import CloseIcon from '../../../../../assets/icons/Close.svg';
import FileText from '../../../../../assets/icons/FileText.svg';
import { download } from '../../../../../utils/download';

type Props = {
  control: Control<
    {
      id?: number | undefined;
      catalog_number: string;
      quantity: number;
      price: number;
      comment: string;
      delivery_due_days: number;
      documents: {
        name?: string | undefined;
        file?: string | undefined;
      }[];
    },
    any
  >;
};

const Documents = ({ control }: Props) => {
  const t = useSelector((state: RootState) => {
    return state.translations.translations[state.translations.appLanguage];
  });

  const { fields, append, remove, update }: any = useFieldArray({
    control: control,
    name: `documents`,
    keyName: '_id',
  });

  const onDocumentChange = e => {
    const file = e.target.files[0];

    let filename = file.name;

    toBase64(file).then(result => {
      if (result) {
        append({
          name: filename,
          file: result,
        });
      } else {
        showNotification(t.product.attach_problem, 'danger');
      }
    });

    e.target.value = '';
  };

  const onDokumentiRemove = i => {
    remove(i);
  };

  return (
    <div className={classes.additional_docs_wrapper}>
      <div>{t.product.additional_docs}</div>

      <div className={classes.docs_wrapper}>
        {fields &&
          fields?.map((doc, i) => {
            return (
              <div
                key={i}
                className={classes.doc_wrapper}
                onClick={() => (doc.id ? download(`/offers/files/${doc.id}`) : FileSaver.saveAs(doc.file, doc.name))}
              >
                <img className={classes.file_icon} src={FileText} alt="File" />
                <div className={classes.file_name}>{doc.name}</div>
                <img
                  className={classes.close_icon}
                  src={CloseIcon}
                  onClick={e => {
                    e.stopPropagation();
                    onDokumentiRemove(i);
                  }}
                  alt="Close"
                />
              </div>
            );
          })}
      </div>
      <label>
        <img src={PlusIcon} alt="Add document" />
        {t.product.add_new_documents}
        <input
          type="file"
          style={{ display: 'none' }}
          accept={'.pdf,image/png,image/jpeg'}
          onChange={e => onDocumentChange(e)}
        />
      </label>
    </div>
  );
};

export default Documents;
