import { RefObject, useEffect, useRef } from 'react';

export function useClickOutside(ref: RefObject<any>, callback: (event: MouseEvent) => void) {
  const cb = useRef(callback);

  useEffect(() => {
    cb.current = callback;
  }, [callback]);

  useEffect(() => {
    function handleMouseDown(event: MouseEvent) {
      if (ref.current && !ref.current.contains(event.target)) {
        cb.current(event);
      }
    }

    document.addEventListener('mousedown', handleMouseDown);

    return () => {
      document.removeEventListener('mousedown', handleMouseDown);
    };
  }, [ref]);
}
