import { useQuery } from '@tanstack/react-query';
import { client } from '../services/http/instance';

interface DailyRecord {
  date: string;
  total: number;
}

interface CompanyStats {
  total: number;
  new: number;
  daily: DailyRecord[];
}

export function useStats() {
  return useQuery({
    queryKey: ['company', 'stats'],
    staleTime: 1000 * 60 * 15,
    cacheTime: Infinity,
    queryFn: () => client.get<CompanyStats>('/companies/stats').then(({ data }) => data),
  });
}
