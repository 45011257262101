import { useQuery, useQueryClient } from '@tanstack/react-query';
import { createContext, useContext, useMemo } from 'react';

import { Role } from '../store/auth/auth.model';
import { Company } from '../types/company';
import { client } from '../services/http/instance';

export interface AuthUser {
  id: number;
  is_verified: number;
  first_name: string;
  last_name: string;
  email: string;
  newsletter: boolean;
  phone_number: string;
  ssn: string | null;
  created_at: string;
  updated_at: string;
  username: string | null;
  company_id: number;
  roles: Role[];
  company: Company;
  cell_phone_number: string;
}

interface Context {
  user: AuthUser | null;
  isAuthenticated: boolean;
  isLoading: boolean;
  hasRole(role: Role): boolean;
  invalidate(): void;
  logout(): void;
}

const TOKEN_KEY = 'user-token';

const AuthContext = createContext<Context | undefined>(undefined);

export function AuthProvider({ children }: React.PropsWithChildren<unknown>) {
  const queryClient = useQueryClient();

  const result = useQuery({
    queryKey: ['@me'],
    cacheTime: Infinity,
    staleTime: 1000 * 60 * 5,
    queryFn: () => {
      if (!localStorage.getItem(TOKEN_KEY)) {
        return null;
      }

      return client.get<AuthUser>('/users/me').then(({ data }) => data);
    },
  });

  const value = useMemo<Context>(
    () => ({
      ...(result.data ? { user: result.data, isAuthenticated: true } : { user: null, isAuthenticated: false }),
      isLoading: result.isLoading,
      hasRole(role) {
        return result.data?.roles.includes(role) ?? false;
      },
      invalidate() {
        queryClient.invalidateQueries({ queryKey: ['@me'], exact: true });
      },
      logout() {
        queryClient.setQueryData(['@me'], null);
        localStorage.removeItem(TOKEN_KEY);
      },
    }),
    [result.data, result.isLoading, queryClient]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export function useAuth() {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth used outside AuthProvider');
  }

  return context;
}
