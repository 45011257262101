import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { client } from '../services/http/instance';
import { Company } from '../types/company';
import { Paginated } from '../types/common';
import { useSelector } from 'react-redux';
import { RootState } from '../store';
import { showNotification } from '../notification_functions/notifications_functions';

export interface OffersQuery {
  pagination: { size: number; page: number };
  sort?: { sortBy?: string; sortOrder?: string };
  filter?: { search?: string; dateFrom?: string; dateTo?: string };
}

export interface AdditionalOffersQuery {
  pagination: { size: number; page: number };
  sort?: { sortBy?: string; sortOrder?: string };
  // filter?: { search?: string; dateFrom?: string; dateTo?: string };
}

interface Product {
  id: number;
  ad_id: number;
  title: string;
  description: string;
  quantity: number;
  channel_name: string;
  created_at: string;
  updated_at: string;
}

export enum OfferProductStatus {
  PENDING = 'pending',
  ACCEPTED = 'accepted',
  DECLINED = 'rejected',
}

interface Amount {
  id: number;
  offer_id: number;
  price: number;
  currency: string;
  status: OfferProductStatus;
  created_at: string;
  updated_at: string;
}

interface Document {
  id: number;
  offer_id: number;
  file_name: string;
  file_url: string;
  file_type: number;
  created_at: string;
  updated_at: string;
}

export interface CompanyOffer {
  id: number;
  quantity: number;
  catalog_number: string;
  comment: string | null;
  created_at: string;
  updated_at: string;
  amount: Amount;
  documents: Document[];
  product: Pick<Product, 'id' | 'title'>;
}

export interface EditDataType {
  id?: number | undefined;
  catalog_number: string;
  quantity: number;
  price: number;
  comment: string;
  delivery_due_days: number;
  documents:
    | {
        name?: string | undefined;
        file?: string | undefined;
      }[]
    | { created_at: string; id: number; name: string; offer_id: number; path: string; updated_at: string }[];
}

export enum ProductOfferStatus {
  Pending = 'pending',
  Actualized = 'actualized',
}

export interface ProductOffer {
  pending_offer_count: number;
  status: ProductOfferStatus;
  updated_at: string;
  company_id: number;
  company: Company;
}

export function useProductOffers(productId: string, query: OffersQuery) {
  return useQuery({
    queryKey: ['products', productId, 'offers', query],
    queryFn: () =>
      client
        .get<Paginated<ProductOffer>>(`/products/${productId}/offers`, {
          params: { ...query.pagination, ...query.sort, ...query.filter },
        })
        .then(({ data }) => data),
    keepPreviousData: true,
  });
}

export function useCompanyOffers(companyId: string, productId: number, query: AdditionalOffersQuery) {
  return useQuery({
    queryKey: ['companies', companyId, 'offers', { ...query, productId }],
    queryFn: () =>
      client
        .get<Paginated<CompanyOffer>>('/offers', {
          params: { ...query.pagination, ...query.sort, productId, companyId },
        })
        .then(({ data }) => data),
    keepPreviousData: true,
  });
}

export function useUpdateStatus() {
  const t = useSelector((state: RootState) => state.translations.translations[state.translations.appLanguage]);
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ id, status }: { id: number; status: OfferProductStatus }) =>
      client.put(`/offers/${id}/status`, { status }),
    onSuccess: (_data, { status, id }) => {
      const message =
        status === OfferProductStatus.DECLINED
          ? t.my_adds_offers.offer_declined
          : status === OfferProductStatus.ACCEPTED
          ? t.my_adds_offers.offer_accepted
          : '';

      showNotification(message, 'success');

      queryClient.setQueriesData<Paginated<CompanyOffer>>(
        {
          predicate: query => {
            return query.queryKey[0] === 'companies' && query.queryKey[2] === 'offers';
          },
          type: 'active',
        },
        old => {
          return !old
            ? undefined
            : {
                ...old,
                data: old?.data.map(item => (item.id === id ? { ...item, amount: { ...item.amount, status } } : item)),
              };
        }
      );

      queryClient.invalidateQueries({
        predicate: query => {
          return query.queryKey[0] === 'products' && query.queryKey[2] === 'offers';
        },
        type: 'active',
      });

      // there might be better way to invalidate this?
      queryClient.invalidateQueries({
        predicate: query => query.queryKey[0] === 'ads' && query.queryKey[2] === 'offers',
        type: 'active',
      });
    },
    onError: (e: any) => {
      if (e.response.data.message && e.response.data.message.includes('still active')) {
        showNotification(t.my_adds_offers.still_active, 'danger');
        return;
      }
      showNotification(t.my_adds_offers.offer_error_message, 'danger');
    },
  });
}

export function useUpdateAuctionOfferStatus() {
  const t = useSelector((state: RootState) => state.translations.translations[state.translations.appLanguage]);

  return useMutation({
    mutationFn: ({ id, status }: { id: string; status: OfferProductStatus }) =>
      client.put(`/auction-offers/${id}/status`, { status }),
    onSuccess: (_data, { status }) => {
      const message =
        status === OfferProductStatus.DECLINED
          ? t.my_adds_offers.offer_declined
          : status === OfferProductStatus.ACCEPTED
          ? t.my_adds_offers.offer_accepted
          : '';

      showNotification(message, 'success');
    },
    onError: () => {
      showNotification(t.my_adds_offers.offer_error_message, 'danger');
    },
  });
}

export function useDeleteOffer() {
  const t = useSelector((state: RootState) => state.translations.translations[state.translations.appLanguage]);

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ id }: { id: number }) => client.delete(`/offers/${id}`),
    onSuccess: (_data, { id }) => {
      showNotification(t.global.success, 'success');
      queryClient.setQueriesData<Paginated<CompanyOffer>>(
        {
          predicate: query => {
            return query.queryKey[0] === 'companies' && query.queryKey[2] === 'offers';
          },
          type: 'active',
        },
        old => {
          return !old
            ? undefined
            : {
                ...old,
                data: old?.data.filter(item => item.id !== id),
              };
        }
      );
      queryClient.invalidateQueries({
        predicate: query => {
          return query.queryKey[0] === 'products' && query.queryKey[2] === 'offers';
        },
        type: 'active',
      });
    },
    onError: () => {
      showNotification(t.global.error_message, 'danger');
    },
  });
}

export function useEditOffer() {
  const t = useSelector((state: RootState) => state.translations.translations[state.translations.appLanguage]);

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ data }: { data: EditDataType }) => client.patch(`/offers/${data.id}`, data),
    onSuccess: _data => {
      showNotification(t.global.success, 'success');
      queryClient.invalidateQueries({
        predicate: query => {
          return query.queryKey[0] === 'companies' && query.queryKey[2] === 'offers';
        },
        type: 'active',
      });
    },
    onError: () => {
      showNotification(t.global.error_message, 'danger');
    },
  });
}
