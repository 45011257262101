import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import DatePicker from 'react-datepicker';

import Search from './../../assets/icons/Search.svg';
import Calendar from './../../assets/icons/Calendar.svg';
import CaretDownGray from './../../assets/icons/CaretDownGray.svg';

import 'react-datepicker/dist/react-datepicker.css';
import '../../components/Tools/Filters/datePickerCustomStyle.scss';
import classes from './Filters.module.scss';
import { IRangeDate } from '../../components/Tools/Filters/model';
import { RootState } from '../../store';
import { useDebounce, useDebouncedCallback } from 'use-debounce';
import Button from '../../components/UI/Button/Button';
import Plus from '../../assets/icons/CloseStroke.svg';
import { Input } from '../../components/Tools/useFormComponents/exports';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import ModalComponent from '../../components/UI/ModalComponent/ModalComponent';
import { useCreateIndustry } from '../../queries/industry';

interface Params {
  searchValue: string | undefined;
  datumOd: string | undefined;
  datumDo: string | undefined;
}

interface Props {
  onSearchChange(params: Params): void;
  title: string;
}

function CreateIndustryModal({ onClose }: { onClose(): void }) {
  const t = useSelector((state: RootState) => {
    return state.translations.translations[state.translations.appLanguage];
  });

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        name: Yup.string().required(t.profile.input_required).trim(),
      }),
    []
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Yup.InferType<typeof validationSchema>>({
    defaultValues: {
      name: '',
    },
    resolver: yupResolver(validationSchema),
  });

  const { mutate, isLoading } = useCreateIndustry();

  const onSubmit = handleSubmit(({ name }) => mutate(name, { onSuccess: onClose }));

  return (
    <ModalComponent
      show
      title={t.industry.create}
      disabled={isLoading}
      onCancel={onClose}
      onAccept={onSubmit}
      component={
        <form onSubmit={onSubmit}>
          <Input {...register('name')} autoFocus error={errors.name?.message} label={t.industry.industry_name} />
        </form>
      }
    />
  );
}

export function Filters(props: Props) {
  const t = useSelector((state: RootState) => {
    return state.translations.translations[state.translations.appLanguage];
  });

  let pickerRef: any = React.createRef();

  const t_advertisements = t.advertisements;
  const t_global = t.global;

  const [searchParameters, setSearchparameters] = useState<Params>({
    searchValue: undefined,
    datumOd: undefined,
    datumDo: undefined,
  });

  const [params] = useDebounce(searchParameters, 500);
  const [open, setOpen] = useState(false);

  const [, setDatumOd] = useState<Date | null>(null);
  const [, setDatumDo] = useState<Date | null>(null);
  const [selectedDateRange, setSelectedDateRange] = useState<IRangeDate>({
    start: null,
    end: null,
  });

  useEffect(() => {
    props.onSearchChange(params);
  }, [params]);

  const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchparameters(state => {
      return {
        ...state,
        searchValue: event.target.value || undefined,
      };
    });
  };

  const CustomDateInput = React.forwardRef(({ value, onClick }: any, ref: any) => {
    return (
      <button className={classes.date_wrapper} onClick={onClick} ref={ref}>
        <img src={Calendar} alt="Calendar" />
        <span>{t_advertisements.date}</span>
        <img src={CaretDownGray} alt="Carret down" />
      </button>
    );
  });

  return (
    <React.Fragment>
      <div className={`${classes.header_container}`}>
        <div className={`${classes.title} text-center`}>{props.title}</div>
        <div className={classes.search_container}>
          <div className={`${classes.search_bar}`}>
            <img src={Search} alt="search" className={classes.icon} />
            <input type="text" placeholder={t_advertisements.search} onChange={onChangeHandler} />
          </div>
          <Button label={t.industry.create} onClick={() => setOpen(true)} icon={Plus} />
        </div>
        <div className={classes.actions}>
          <div className={classes.date_container}>
            <DatePicker
              ref={pickerRef}
              selected={selectedDateRange.start}
              startDate={selectedDateRange.start}
              endDate={selectedDateRange.end}
              onChange={dates => {
                const [start, end] = dates;
                setSelectedDateRange({ start, end });
              }}
              selectsRange
              shouldCloseOnSelect={false}
              showMonthDropdown
              showYearDropdown
              scrollableYearDropdown
              dropdownMode="select"
              popperClassName="date_picker_custom_style"
              customInput={<CustomDateInput />}
            >
              <div>
                <button
                  onClick={() => {
                    setSelectedDateRange({ start: null, end: null });
                    setSearchparameters(state => {
                      return {
                        ...state,
                        datumOd: undefined,
                        datumDo: undefined,
                      };
                    });
                    pickerRef.current.setOpen();
                  }}
                >
                  {t_global.cancel}
                </button>
                <button
                  onClick={() => {
                    if (selectedDateRange.start && selectedDateRange.end) {
                      setDatumOd(selectedDateRange.start);
                      setDatumDo(selectedDateRange.end);
                      setSearchparameters(state => {
                        return {
                          ...state,
                          datumOd: selectedDateRange.start?.toISOString() ?? undefined,
                          datumDo: selectedDateRange.end?.toISOString() ?? undefined,
                        };
                      });
                    }
                    pickerRef.current.setOpen();
                  }}
                >
                  {t_global.confirm_date}
                </button>
              </div>
            </DatePicker>
          </div>
        </div>
      </div>
      {open && <CreateIndustryModal onClose={() => setOpen(false)} />}
    </React.Fragment>
  );
}
