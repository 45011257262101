import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LocalStorageEnum } from '../../enums/local-storage-keys';
import { ILogin, ILoginAction, IStateUser } from './auth.model';

const initialState: ILogin = {
  token: '',
  username: '',
  isLogged: false,
  isLoading: false,
  user: {
    user: {
      id: '',
      random_number_id: '',
      maticen_broj_ekonomski_operator: '',
      edinstven_danocen_broj: '',
      naziv: '',
      address: '',
      city: '',
      municipality: '',
      postal_code: '',
      phone_number: '',
      fax: '',
      company_email: '',
      web_location: '',
      company_industries: [],
      document_file: {},
      first_name: '',
      last_name: '',
      username: '',
      ssn: '',
      roles: [],
      cell_phone_number: '',
      user_phone_number: '',
      user_email: '',
      password: '',
      logo: '',
      company_info: '',
    },
    userId: '',
    access_token: '',
  },
  openModal: false,
};

const authSlice = createSlice({
  name: 'auth',
  initialState: initialState,
  reducers: {
    toggleLoader: state => {
      state.isLoading = !state.isLoading;
    },
    login: (state, action: PayloadAction<ILoginAction>) => {
      localStorage.setItem(LocalStorageEnum.UserToken, action.payload.token);
      state.token = action.payload.token;
      state.username = action.payload.username;
      state.isLogged = true;
    },
    logout: state => {
      localStorage.removeItem(LocalStorageEnum.UserToken);
      state.token = '';
      state.isLogged = false;
    },
    putUserInState: (state, action: PayloadAction<IStateUser>) => {
      localStorage.setItem(LocalStorageEnum.UserToken, action.payload.access_token);
      state.user = action.payload;
      state.isLogged = true;
      state.token = action.payload.access_token;
    },
    updateUser: (state, action: any) => {
      state.user.user = action.payload;
    },
    openModal: state => {
      state.openModal = true;
    },
    closeModal: state => {
      state.openModal = false;
    },
  },
});

export const authActions = authSlice.actions;
export default authSlice.reducer;
