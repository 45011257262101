import { useSelector } from 'react-redux';
import clsx from 'clsx';

import classes from './StatusDisplay.module.scss';
import { RootState } from '../store';
import { AdStatus } from '../types/ad';

interface Props {
  status: AdStatus;
}

export function AdStatusDisplay({ status }: Props) {
  const t = useSelector((state: RootState) => {
    return state.translations.translations[state.translations.appLanguage]['advertisements'];
  });

  return (
    <div
      className={clsx(classes.container, {
        [classes.pending]: [AdStatus.Opened, AdStatus.Pending].includes(status),
        [classes.accepted]: status === AdStatus.Actualized,
        [classes.rejected]: status === AdStatus.Expired,
      })}
    >
      <span />
      {t[status]}
    </div>
  );
}
