import React, { useMemo } from 'react';
import { Modal } from 'react-bootstrap';
import classes from '../ConfirmModalCompany/ConfirmModalCompany.module.scss';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../store';
import FileText from '../../../../../assets/icons/FileText.svg';
import { download } from '../../../../../utils/download';

export type Offer = {
  amount: { id: number; offer_id: number; price: number; currency: string; status: string; updated_at: string };
  catalog_number: string;
  comment: string;
  created_at: string;
  delivery_due_days: number;
  documents: { created_at: string; id: number; name: string; offer_id: number; path: string; updated_at: string }[];
  id: number;
  product: { id: number; title: string };
  quantity: number;
  updated_at: string;
};

type Props = {
  openViewModal: {
    show: boolean;
    data: Offer;
  };
  handleOfferViewClose: () => void;
  ad: any;
};

const EditOfferModal = ({ openViewModal, handleOfferViewClose, ad }: Props) => {
  const t = useSelector((state: RootState) => {
    return state.translations.translations[state.translations.appLanguage];
  });

  return (
    <Modal show={openViewModal.show} className={classes.modal_wrapper} centered size="lg" onHide={handleOfferViewClose}>
      <Modal.Header className={classes.custom_header} closeButton>
        <Modal.Title>{t.product.offer_details}</Modal.Title>
      </Modal.Header>

      <Modal.Body className={classes.custom_body}>
        <div className={classes.split}>
          <div className={`${classes.info_container} ${classes.col_3}`}>
            <div className={classes.title}>{t.offers.catalog_number}</div>
            <div className={classes.info}>{openViewModal.data.catalog_number}</div>
          </div>
          <div className={`${classes.info_container} ${classes.col_3}`}>
            <div className={classes.title}>{t.offers.due_date_asked}</div>
            <div className={classes.info}>{ad.delivery_due_days}</div>
          </div>
          <div className={`${classes.info_container} ${classes.col_3}`}>
            <div className={classes.title}>{t.offers.due_date_offered}</div>
            <div className={classes.info}>{openViewModal.data.delivery_due_days}</div>
          </div>
        </div>
        <div className={classes.split} style={{ borderBottom: '1px solid #dfdfdf' }}>
          <div className={`${classes.info_container} ${classes.col_3}`}>
            <div className={classes.title}>{t.offers.offered_quantity}</div>
            <div className={classes.info}>{openViewModal.data.quantity}</div>
          </div>
          <div className={`${classes.info_container} ${classes.col_3}`}>
            <div className={classes.title}>{t.product.price}</div>
            <div className={classes.info}>{openViewModal.data.amount.price}</div>
          </div>
          <div className={`${classes.info_container} ${classes.col_3}`}>
            <div className={classes.title}>{t.offers.total_price}</div>
            <div className={classes.info}>
              {Number(openViewModal.data.amount.price) * Number(ad.products[0].quantity)}
            </div>
          </div>
        </div>
        <div style={{ marginTop: '12px' }}>
          <div className={classes.info_container}>
            <div className={classes.title}>{t.product.additional_docs}</div>
          </div>
          <div className={classes.additional_docs_wrapper} style={{ paddingTop: 0 }}>
            <div className={classes.docs_wrapper}>
              {openViewModal.data.documents &&
                openViewModal.data.documents?.map((doc, i) => {
                  return (
                    <div
                      key={i}
                      className={classes.doc_wrapper}
                      onClick={() => doc.id && download(`/offers/files/${doc.id}`)}
                    >
                      <img className={classes.file_icon} src={FileText} alt="File" />
                      <div className={classes.file_name}>{doc.name}</div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>

        <div className={classes.info_container} style={{ marginTop: '12px' }}>
          <div className={classes.title}>{t.product.comment}</div>
          <div className={classes.info}>{openViewModal.data.comment}</div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default EditOfferModal;
