import React from 'react';
import classes from './IconButton.module.scss';
import { IIconButton } from './models/icon-button.model';

const IconButton = (
  props: IIconButton & { square?: boolean; type?: 'button' | 'submit'; disabled?: boolean; variant?: 'danger' }
) => {
  let classList = [classes.Button, 'btn'];
  classList.push(props.class ? classes[props.class] : classes.accent);

  if (props.borderless) {
    classList.push(classes.Borderless);
  }

  if (props.borderlessTransparent) {
    classList.push(classes.BorderlessTransparent);
  }

  if (props.hoverNone) {
    classList.push(classes.HoverNone);
  }

  if (props.small) {
    classList.push(classes.Small);
  }

  if (props.square) {
    classList.push(classes.Square);
  }

  if (props.variant) {
    classList.push(classes[props.variant]);
  }

  return (
    <React.Fragment>
      <button
        type={props.type ?? 'button'}
        disabled={props.disabled}
        className={classList.join(' ')}
        onClick={props.onClick}
      >
        <div className={classes.ButtonContent}>
          {props.icon && <img src={props.icon} alt={props.icon} />}
          {props.customIcon}
        </div>
      </button>
    </React.Fragment>
  );
};

export default IconButton;
