import classes from './SortCaret.module.scss';

interface Props {
  order: string;
  column: string;
}

export function SortCaret({ order, column }: Props) {
  if (!order) {
    return (
      <span style={{ display: 'inline-block', position: 'relative', top: '-5px' }}>
        <span className={classes.sort_wrapper}>
          <span className={`${classes.caret} ${classes.caret_up}`}></span>
          <span className={classes.caret}></span>
        </span>
      </span>
    );
  }

  if (order === 'asc') {
    return (
      <span style={{ display: 'inline-block', position: 'relative', top: '-2px' }}>
        <span className={classes.sort_wrapper}>
          <span className={`${classes.caret} ${classes.caret_up}`}></span>
        </span>
      </span>
    );
  }

  if (order === 'desc') {
    return (
      <span style={{ display: 'inline-block', position: 'relative', top: '-2px' }}>
        <span className={classes.sort_wrapper}>
          <span className={classes.caret}></span>
        </span>
      </span>
    );
  }

  return null;
}
