import BootstrapTable from 'react-bootstrap-table-next';
import Checkmark from '../../assets/icons/CheckmarkGrey.svg';
import Close from '../../assets/icons/Close.svg';
import Pencil from '../../assets/icons/Pencil.svg';
import Trash from '../../assets/icons/TrashIconRed.svg';
import Refresh from '../../assets/icons/Refresh.svg';

import Page from '../../components/Layout/Page/Page';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { formatDate } from '../../utils/formatDate';
import { createContext, useContext, useMemo, useRef, useState } from 'react';
import { SortCaret } from '../../components/SortCaret';
import { useDeleteIndustry, useIndustries, useRestoreIndustry, useUpdateIndustry } from '../../queries/industry';
import classes from './Industries.module.scss';
import { Input } from '../../components/Tools/useFormComponents/exports';
import { Filters } from './Filters';
import { useClickOutside } from '../../hooks/useClickOutside';
import IconButton from '../../components/UI/IconButton/IconButton';
import ModalComponent from '../../components/UI/ModalComponent/ModalComponent';
import { showNotification } from '../../notification_functions/notifications_functions';

interface Context {
  rowId: number | null;
  setRowId(value: number | null): void;
}

const EditContext = createContext<Context | undefined>(undefined);

function EditContextProvider({ children }: React.PropsWithChildren<unknown>) {
  const [rowId, setRowId] = useState<number | null>(null);

  const value = useMemo(
    () => ({
      rowId,
      setRowId,
    }),
    [rowId]
  );

  return <EditContext.Provider value={value}>{children}</EditContext.Provider>;
}

function NameForm({ id, initialValue }: { id: number; initialValue: string }) {
  const [name, setName] = useState(initialValue);
  const { mutate, isLoading } = useUpdateIndustry(id);
  const context = useContext(EditContext);
  const ref = useRef(null);
  const t = useSelector((state: RootState) => state.translations.translations[state.translations.appLanguage]);

  useClickOutside(ref, () => {
    context?.setRowId(null);
  });

  return (
    <form
      ref={ref}
      className={classes.name_form}
      onSubmit={e => {
        e.preventDefault();

        const value = name.trim();

        if (value === initialValue) {
          context?.setRowId(null);
          return;
        }

        if (!value) {
          showNotification(t.industry.missing_name, 'danger');
          return;
        }

        mutate(name, { onSuccess: () => context?.setRowId(null) });
      }}
    >
      <Input ignoreError value={name} onChange={e => setName(e.target.value)} autoFocus />
      <div className={classes.form_actions}>
        <IconButton
          icon={Checkmark}
          borderless
          disabled={isLoading}
          type="submit"
          small
          class="other-gray-accent"
          square
        />
        <IconButton
          icon={Close}
          borderless
          small
          square
          class="other-gray-accent"
          disabled={isLoading}
          onClick={() => context?.setRowId(null)}
        />
      </div>
    </form>
  );
}

function Name({ row, cell }: { row: any; cell: any }) {
  const context = useContext(EditContext);

  if (context?.rowId === row.id) {
    return <NameForm id={row.id} initialValue={cell} />;
  }

  return <div className={classes.name}>{cell}</div>;
}

function Actions({ row, cell }: { row: any; cell: any }) {
  const context = useContext(EditContext);
  const [deleting, setDeleting] = useState(false);
  const remove = useDeleteIndustry(row.id);
  const restore = useRestoreIndustry(row.id);
  const t = useSelector((state: RootState) => state.translations.translations[state.translations.appLanguage]);

  return (
    <div>
      <IconButton
        icon={Pencil}
        borderless
        square
        class="transparent"
        hoverNone
        disabled={restore.isLoading}
        onClick={() => context?.setRowId(row.id)}
      />
      <IconButton
        icon={row.deleted_at ? Refresh : Trash}
        disabled={restore.isLoading}
        borderless
        hoverNone
        variant="danger"
        square
        class="transparent"
        onClick={() => (row.deleted_at ? restore.mutate(undefined) : setDeleting(true))}
      />
      {deleting && (
        <ModalComponent
          show
          title={t.global.are_you_sure}
          onCancel={() => setDeleting(false)}
          disabled={remove.isLoading}
          onAccept={() =>
            remove.mutate(undefined, {
              onSuccess: () => setDeleting(false),
            })
          }
          text={t.industry.industry_delete_warning}
        />
      )}
    </div>
  );
}

export function Industries() {
  const [query, setQuery] = useState<{ sortOrder?: string; sortBy?: string; search?: string }>({});
  const [tab, setTab] = useState('active');
  const { data = [], isLoading } = useIndustries({ ...query, status: tab });

  const t = useSelector((state: RootState) => {
    return state.translations.translations[state.translations.appLanguage];
  });

  const columns = useMemo(
    () => [
      {
        dataField: 'name',
        text: t.industry.name,
        formatter: (cell, row) => <Name cell={cell} row={row} />,
        sort: true,
      },
      {
        dataField: 'created_at',
        text: t.global.created_at,
        formatter: cell => formatDate(cell),
        sort: true,
      },
      {
        dataField: 'updated_at',
        text: t.global.modified_at,
        formatter: cell => formatDate(cell),
        sort: true,
      },
      {
        dataField: 'actions',
        text: t.global.actions,
        formatter: (cell, row) => <Actions cell={cell} row={row} />,
      },
    ],
    [t]
  );

  return (
    <Page>
      <Filters
        title={t.global.industries}
        onSearchChange={({ searchValue, datumDo, datumOd }) =>
          setQuery(old => ({ ...old, search: searchValue, dateFrom: datumOd, dateTo: datumDo }))
        }
      />
      <div className={classes.table_container}>
        <div className={classes.buttons_wrapper}>
          <div
            className={`${classes.button} ${tab === 'active' ? classes.active : ''}`}
            onClick={() => setTab('active')}
          >
            {t.advertisements.active}
          </div>
          <div
            className={`${classes.button} ${tab === 'inactive' ? classes.active : ''}`}
            onClick={() => setTab('inactive')}
          >
            {t.global.inactive}
          </div>
        </div>
        <EditContextProvider>
          <BootstrapTable
            keyField="id"
            remote={{
              pagination: true,
            }}
            data={data}
            columns={columns}
            onTableChange={(_, { sortField, sortOrder }) => setQuery(old => ({ ...old, sortBy: sortField, sortOrder }))}
            classes={classes.table}
            noDataIndication={isLoading ? t.global.loading : t.global.no_data}
            sort={{
              sortCaret: (order, column) => <SortCaret column={column} order={order} />,
              order: 'asc',
            }}
          />
        </EditContextProvider>
      </div>
    </Page>
  );
}
