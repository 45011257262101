import { UserInfo } from 'firebase/auth';

export interface ILogin {
  token: string;
  isLogged: boolean;
  isLoading: boolean;
  username: string;
  user: IStateUser;
  openModal: boolean;
}

export interface ILoginRegistration {
  first_name: string;
  last_name: string;
  username: string;
  token: string;
}

export interface ILoginAction {
  token: string;
  username: string;
}

export interface ILoginPayload {
  username: string;
  password: string;
}

export enum Role {
  SystemAdmin = 'system_admin',
}

export interface IUser {
  email?: string | undefined;
  id: string;
  random_number_id: string;
  maticen_broj_ekonomski_operator: string;
  edinstven_danocen_broj: string;
  naziv: string;
  address: string;
  city: string;
  municipality: string;
  postal_code: string;
  phone_number?: string;
  fax?: string;
  company_email: string;
  web_location?: string;
  company_industries: Array<Number>;
  document_file?: any;
  first_name: string;
  last_name: string;
  username: string;
  ssn: string;
  cell_phone_number?: string;
  user_phone_number?: string;
  user_email: string;
  company_phone?: string;
  password: string;
  logo?: any;
  roles?: Role[];
  company_info?: string;
}

export interface IFirebaseUserMetadata {
  createdAt: string;
  creationTime: string;
  lastLoginAt: string;
  lastSignInTime: string;
  uid: string;
}

export interface IFirebaseUser {
  accessToken: string;
  displayName: string;
  email: string;
  metadata: IFirebaseUserMetadata;
  phoneNumber: string;
  photoURL;
  string;
}

export interface IStateUser {
  user: IUser;
  userId: string;
  access_token: string;
}
