import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import DatePicker from 'react-datepicker';

import { IFilters, ISearchParameters, IRangeDate } from './model';
import { RootState } from '../../../store';
import AdvertisementPdf from '../../Printing/MyAdvertisementsPDF/MyAdvertisementsPDF';

import Search from './../../../assets/icons/Search.svg';
import Download from './../../../assets/icons/Download.svg';
import Calendar from './../../../assets/icons/Calendar.svg';
import CaretDownGray from './../../../assets/icons/CaretDownGray.svg';

import 'react-datepicker/dist/react-datepicker.css';
import './datePickerCustomStyle.scss';
import classes from './Filters.module.scss';
import { Dropdown, Form } from 'react-bootstrap';
import { httpGet } from '../../../services/http';
import Filter from '../../../assets/icons/Filter.svg';
import { useIndustries } from '../../../queries/industry';

const Filters = (props: IFilters & { industryView?: 'ads' | 'offers' }) => {
  const t = useSelector((state: RootState) => {
    return state.translations.translations[state.translations.appLanguage];
  });

  let pickerRef: any = React.createRef();

  const t_advertisements = t.advertisements;
  const t_global = t.global;
  const t_current_ads = t.current_advertisements;

  const [viewPdf, setViewPdf] = useState<boolean>(false);
  const [searchParameters, setSearchparameters] = useState<ISearchParameters>({
    searchValue: '',
    datumOd: '',
    datumDo: '',
    category_id: 0,
  });
  const [, setDatumOd] = useState<Date | null>(null);
  const [, setDatumDo] = useState<Date | null>(null);
  const { data: categories = [] } = useIndustries({ view: props.industryView });
  const [selectedDateRange, setSelectedDateRange] = useState<IRangeDate>({
    start: null,
    end: null,
  });

  useEffect(() => {
    const triggerTimeout = setTimeout(() => {
      props.onSearchChange(searchParameters);
    }, 500);

    return () => clearTimeout(triggerTimeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParameters]);

  const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchparameters(state => {
      return {
        ...state,
        searchValue: event.target.value,
      };
    });
  };

  const previewPdfHandler = () => {
    setViewPdf(true);
  };

  const onCancelHandler = () => {
    setViewPdf(false);
  };

  const CustomDateInput = React.forwardRef(({ value, onClick }: any, ref: any) => {
    return (
      <button className={classes.date_wrapper} onClick={onClick} ref={ref}>
        <img src={Calendar} alt="Calendar" />
        <span>{t_advertisements.date}</span>
        <img src={CaretDownGray} alt="Carret down" />
      </button>
    );
  });
  const onCategoryChange = (value: string | null) => {
    setSearchparameters(state => {
      return {
        ...state,
        category_id: value === '0' ? null : value,
      };
    });
  };

  const CustomCategoryMenu = React.forwardRef(
    ({ children, style, className, 'aria-labelledby': labeledBy }: any, ref: any) => {
      const [value, setValue] = useState('');
      const categories = React.Children.toArray(children).filter(
        (child: any) => child.props.children !== t_current_ads.no_categories
      ).length;
      return (
        <div ref={ref} style={style} className={className} aria-labelledby={labeledBy}>
          <Form.Control
            autoFocus
            className={`mx-3 my-2 w-aut ${classes.filter_input}`}
            placeholder={t_current_ads.search_categies}
            onChange={e => setValue(e.target.value)}
            value={value}
            disabled={categories === 0}
          />
          <ul className="list-unstyled">
            {React.Children.toArray(children).filter(
              (child: any) => !value || child.props.children.toLowerCase().startsWith(value)
            )}
          </ul>
        </div>
      );
    }
  );

  return (
    <React.Fragment>
      <div className={`${classes.header_container}`}>
        <div className={`${classes.title} text-center`}>{props.title}</div>

        <div className={`${classes.search_bar}`}>
          <img src={Search} alt="search" className={classes.icon} />
          <input type="text" placeholder={t_advertisements.search} onChange={onChangeHandler} />
        </div>

        <div className={classes.actions}>
          {!props.noCategoryPicker && (
            <Dropdown
              className={classes.dropdown_filter_wrapper}
              onSelect={(eventKey, event) => {
                onCategoryChange(eventKey);
              }}
            >
              <Dropdown.Toggle className={classes.button} id="categories_filter">
                <img src={Filter} alt="Filter" />
                {/* {props.categoryId ? categories[props.categoryId].name :} */}
                {t_current_ads.category}
              </Dropdown.Toggle>

              <Dropdown.Menu as={CustomCategoryMenu} align="end" className={classes.filter_menu}>
                {categories.length > 0 ? (
                  <>
                    <Dropdown.Item
                      className={classes.option}
                      eventKey={0}
                      key={0}
                      active={[0, null, undefined].includes(Number(searchParameters.category_id))}
                      style={
                        [0, null, undefined].includes(Number(searchParameters.category_id)) ? { color: 'white' } : {}
                      }
                    >
                      {t_global.all_categories}
                    </Dropdown.Item>
                    {categories.map((category, index) => {
                      return (
                        <Dropdown.Item
                          className={classes.option}
                          eventKey={category.id}
                          key={index}
                          active={searchParameters.category_id == category.id}
                          style={searchParameters.category_id == category.id ? { color: 'white' } : {}}
                        >
                          {category.name}
                        </Dropdown.Item>
                      );
                    })}
                  </>
                ) : (
                  <div style={{ textAlign: 'center' }}>{t_current_ads.no_categories}</div>
                )}
              </Dropdown.Menu>
            </Dropdown>
          )}
          {/* <button
                        className={classes.download_list}
                        onClick={previewPdfHandler}
                    >
                        <img src={Download} alt="download" />
                        <span className="">{t_advertisements.download_list}</span>
                    </button> */}
          {!props.noDatePicker && (
            <div className={classes.date_container}>
              <DatePicker
                ref={pickerRef}
                selected={selectedDateRange.start}
                startDate={selectedDateRange.start}
                endDate={selectedDateRange.end}
                onChange={dates => {
                  const [start, end] = dates;
                  setSelectedDateRange({ start, end });
                }}
                selectsRange
                shouldCloseOnSelect={false}
                showMonthDropdown
                showYearDropdown
                scrollableYearDropdown
                dropdownMode="select"
                popperClassName="date_picker_custom_style"
                customInput={<CustomDateInput />}
              >
                <div>
                  <button
                    onClick={() => {
                      setSelectedDateRange({ start: null, end: null });
                      setSearchparameters(state => {
                        return {
                          ...state,
                          datumOd: '',
                          datumDo: '',
                        };
                      });
                      pickerRef.current.setOpen();
                    }}
                  >
                    {t_global.cancel}
                  </button>
                  <button
                    onClick={() => {
                      if (selectedDateRange.start && selectedDateRange.end) {
                        setDatumOd(selectedDateRange.start);
                        setDatumDo(selectedDateRange.end);
                        setSearchparameters(state => {
                          return {
                            ...state,
                            datumOd: selectedDateRange.start || '',
                            datumDo: selectedDateRange.end || '',
                          };
                        });
                      }
                      pickerRef.current.setOpen();
                    }}
                  >
                    {t_global.confirm_date}
                  </button>
                </div>
              </DatePicker>
            </div>
          )}
        </div>
      </div>

      {viewPdf && <AdvertisementPdf data={props.advertisements} onCancel={onCancelHandler} />}
    </React.Fragment>
  );
};

export default Filters;
