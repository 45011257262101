import React, { useState } from 'react';
import classes from './UserInfo.module.scss';

import LockLight from './../../../../assets/icons/LockLight.svg';

import { useSelector } from 'react-redux';
import { RootState } from '../../../../store';

import * as _ from 'lodash';
import { Link as BaseLink, LinkProps } from 'react-router-dom';
import { RoutesEnum } from '../../../../enums/routes';
import { Role } from '../../../../store/auth/auth.model';
import { useAuth } from '../../../../contexts/AuthContext';

function Link({ children, ...props }: LinkProps) {
  const auth = useAuth();

  if (auth.hasRole(Role.SystemAdmin)) {
    return <>{children}</>;
  }

  return <BaseLink {...props}>{children}</BaseLink>;
}

const UserInfo = () => {
  const [logo, setLogo] = useState('');
  const isAnon = useSelector((state: RootState) => state.sidebar.anonymousCreate);

  const user = useSelector((state: RootState) => state.auth.user.user);
  // const cloneUser = _.cloneDeep(user);

  let userInfo;
  let userIcon;

  if (!isAnon) {
    userInfo = (
      <React.Fragment>
        <h5>
          <Link to={RoutesEnum.Profile}>{user?.naziv}</Link>
          {/* <img src={Verified} alt="verified" /> */}
        </h5>
      </React.Fragment>
    );
    // alert(logo);
    userIcon = (
      <Link to={RoutesEnum.Profile}>
        <img src={`${process.env.REACT_APP_STATIC_URL}/companies/${user.id}/logos/${user.logo}`} alt="user avatar" />
      </Link>
    );
  } else {
    userInfo = (
      <React.Fragment>
        <h5>
          <Link to={RoutesEnum.Profile}>Компанија #{user?.random_number_id}</Link>
        </h5>
      </React.Fragment>
    );

    userIcon = (
      <React.Fragment>
        <div className={classes.Anon}>
          <img className={classes.LockShade} src={LockLight} alt="lock light" />
          <img className={classes.Lock} src={LockLight} alt="lock light" />
        </div>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <div className={classes.UserInfoContainer}>
        <div className={classes.UserAvatarLogo}>{userIcon}</div>
        <div className={classes.UserName}>{userInfo}</div>
      </div>
    </React.Fragment>
  );
};

export default UserInfo;
