import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';

import Page from '../../components/Layout/Page/Page';
import OfferHeader from './Components/Header/OfferHeader';
import { RootState } from '../../store';
import { hideNotifications } from '../../notification_functions/notifications_functions';
import NoImageIcon from '../../assets/icons/No-image-icon.svg';
import classes from './OffersPreview.module.scss';
import { getLogoUrl } from '../../utils/company';
import { useAd } from '../../queries/ad';
import { OfferProductStatus } from '../../queries/offer';
import { httpGet } from '../../services/http';
import { Tab, Tabs } from 'react-bootstrap';
import ProductOffersTab from './ProductOffersTab';

const Back = () => {
  const t = useSelector((state: RootState) => {
    return state.translations.translations[state.translations.appLanguage];
  });

  const navigate = useNavigate();

  return (
    <div className={classes.header_content_container}>
      <button type="button" className={classes['back_btn']} onClick={() => navigate(-1)}>
        &#60; {t.global['back']}
      </button>
    </div>
  );
};

export function OffersPreviewNew({ own }: { own?: boolean }) {
  const { id } = useParams();

  const [products, setProducts] = useState<any>(null);
  const [currentTab, setCurrentTab] = useState<number>();

  const t = useSelector((state: RootState) => {
    return state.translations.translations[state.translations.appLanguage];
  });

  useEffect(() => {
    fetchProducts();
    return () => {
      hideNotifications();
    };
  }, []);

  const ad = useAd(id!);
  const fetchProducts = async () => {
    const response = await httpGet<any>(`ads/${id}/products`);
    setProducts(response.data);
  };

  const [showConfirmModal, setShowConfirmModal] = useState<
    { show: false } | { show: true; status: OfferProductStatus; id: string }
  >({ show: false });

  return (
    <React.Fragment>
      <Page showBackBbtn={Back}>
        <OfferHeader
          Info={{
            date: ad?.data?.created_at!,
            number: ad?.data?.id!,
          }}
        />
        <div className={classes.product_wrapper}>
          <div className={classes.product_info}>
            <div className={classes.title_wrapper}>
              <div className={classes.image_wrapper}>
                <img
                  src={ad.data?.company?.logo ? getLogoUrl(ad.data.company) : ''}
                  alt="Company Logo"
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null;
                    currentTarget.src = NoImageIcon;
                  }}
                />
              </div>
              <div className={classes.info_wrapper}>
                <div className={classes.title}>{ad.data?.title}</div>
                <div className={classes.category}>{ad.data?.industry?.name}</div>
              </div>
            </div>
          </div>
        </div>
        <Tabs onSelect={key => setCurrentTab(Number(key))} activeKey={currentTab} unmountOnExit transition={false}>
          {products &&
            ad.data &&
            products?.map((product, index) => (
              <Tab title={`${t.product.product} ${index + 1}`} eventKey={product.id} key={product.id}>
                <ProductOffersTab
                  ad={ad}
                  id={Number(product.id)}
                  own={!!own}
                  showConfirmModal={showConfirmModal}
                  setShowConfirmModal={setShowConfirmModal}
                  product={product}
                />
              </Tab>
            ))}
        </Tabs>
      </Page>
    </React.Fragment>
  );
}
